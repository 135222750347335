import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MaterialIconComponent } from '../material-icon/material-icon.component';
import { DialogComponent } from './dialog.component';
import { ShoelaceModule } from 'shoelace-style-angular';
import { CloseConfirmComponent } from './close-confirm/close-confirm.component';
import { TranslateModule } from 'src/shared/modules/translate/translate.module';
import { PlmtButtonComponent } from 'plmt-core-library';

@NgModule({
    imports: [
        CommonModule,
        MaterialIconComponent,
        ShoelaceModule,
        TranslateModule,
        PlmtButtonComponent,
    ],
    exports: [DialogComponent],
    declarations: [DialogComponent, CloseConfirmComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DialogModule {}
