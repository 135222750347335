import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { IconInput } from '../../interfaces/sl-icon.type';

@Component({
    selector: 'sl-icon-container',
    templateUrl: './sl-icon-container.component.html',
    styleUrls: ['./sl-icon-container.component.less'],
    standalone: true,
    imports: [NgIf, NgTemplateOutlet],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IconContainerComponent {
    @Input() icon?: IconInput;

    isString(value: IconInput | undefined): value is string {
        return typeof value === 'string';
    }
}
