import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TestIdDirectiveModule } from '../../../directives/test-id.directive';

import { FormModule } from '../../../modules/form/form.module';
import { TooltipifyModule } from '../../../modules/tooltipify/tooltipify.module';
import { TranslateModule } from '../../../modules/translate/translate.module';
import { CalculateColumnIconModule } from '../../calculate-column-icon/calculate-column-icon.module';
import { ColumnTypeIconModule } from '../../column-type-icon/column-type-icon.module';
import { FilterValueModule } from '../../filter-value/filter-value.module';
import { SuggestModule } from '../../suggest/suggest.module';
import { FilterValueDefaultModule } from '../filter-value-default/filter-value-default.module';
import { ExtendedSettingsComponent } from './extended-settings.component';
import { TreeFilterComponent } from './tree-filter/tree-filter.component';
import { TreeViewModule } from '../../tree-view/tree-view.module';
import { PlmtSelectModule } from '../../../plmt-ui-components/plmt-select/plmt-select.module';
import { IsColumnResultUnsavedPipeModule } from '../../../pipes/save-in-dataset.pipe';

import { LocalFiltersModule, PlmtButtonComponent } from 'plmt-core-library';

@NgModule({
    imports: [
        CalculateColumnIconModule,
        ColumnTypeIconModule,
        CommonModule,
        FilterValueModule,
        FilterValueDefaultModule,
        FormModule,
        IsColumnResultUnsavedPipeModule,
        SuggestModule,
        TranslateModule,
        TooltipifyModule,
        LocalFiltersModule,
        TreeViewModule,
        TestIdDirectiveModule,
        PlmtSelectModule,
        PlmtButtonComponent,
    ],
    exports: [ExtendedSettingsComponent],
    declarations: [ExtendedSettingsComponent, TreeFilterComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ExtendedSettingsModule {}
