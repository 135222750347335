<sl-button
    [variant]="variant"
    [size]="size"
    [loading]="loading"
    [disabled]="disabled"
    [style.pointer-events]="disabled ? 'none' : 'auto'"
    [type]="type"
    [pill]="pill === ''"
    [slot]="slot"
    [circle]="circle === ''"
    [class.light]="light === ''"
    [class.clear]="clear === ''"
    [class.outline]="outline === ''"
    (click)="clicked.emit($event)"
>
    <div class="plmt__container">
        <sl-icon-container
            [icon]="prefixIcon"
            [class.prefix-margin]="
                (content.childNodes.length || suffixIcon) && prefixIcon
            "
        ></sl-icon-container>

        <div #content class="content">
            <ng-content></ng-content>
        </div>

        <sl-icon-container
            [icon]="suffixIcon"
            [class.suffix-margin]="
                (content.childNodes.length || prefixIcon) && suffixIcon
            "
        ></sl-icon-container>
    </div>
</sl-button>
