<sl-dialog
    #dialog
    class="center"
    [label]="'_$.modalCloseConfirm.title' | translate"
>
    {{ '_$.modalCloseConfirm.description' | translate }}

    <plmt-button
        slot="footer"
        variant="primary"
        test-id="Cancel"
        (clicked)="dialog.hide()"
    >
        {{ '$.cancel' | translate }}
    </plmt-button>

    <plmt-button
        slot="footer"
        variant="danger"
        test-id="Exit"
        outline
        (clicked)="onClose.emit(); dialog.hide()"
    >
        {{ '_$.modalCloseConfirm.ok' | translate }}
    </plmt-button>
</sl-dialog>
