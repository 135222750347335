<sl-dialog
    [label]="'_$.navigation.profile.photoProfile' | translate"
    [(open)]="isOpen"
    (sl-after-hide)="deletePhoto()"
>
    <div class="spinner" *ngIf="loading">
        <sl-spinner></sl-spinner>
    </div>
    <ng-container *ngIf="imageUrl || imageFile">
        <image-cropper
            format="png"
            style="--cropper-outline-color: #c4c4c4"
            [imageURL]="imageUrl"
            [imageFile]="imageFile"
            [cropper]="cropperPosition"
            [roundCropper]="true"
            [containWithinAspectRatio]="true"
            [backgroundColor]="'#ffffff'"
            (cropperReady)="cropperReady()"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="loadStatus = LoadingStatus.Loading"
            (loadImageFailed)="loadStatus = LoadingStatus.Reject"
            (mousedown)="$event.preventDefault()"
        >
        </image-cropper>
    </ng-container>

    <core-file
        *ngIf="!loading"
        accept="image/jpeg, image/jpg, image/png"
        [loadStatus]="loadStatus"
        [description]="[
            '_$.navigation.profile.upload.description',
            '_$.navigation.profile.upload.description.subtitle'
        ]"
        [customLoadButton]="customLoadButton"
        (onPick)="pickFile($event)"
    ></core-file>

    <plmt-button
        *ngIf="showDeleteButton"
        slot="footer"
        variant="danger"
        outline
        (clicked)="deletePhoto()"
    >
        {{ '$.delete' | translate }}
    </plmt-button>

    <main slot="footer">
        <plmt-button
            variant="primary"
            test-id="Cancel"
            outline
            (clicked)="this.isOpen = false"
        >
            {{ '$.cancel' | translate }}
        </plmt-button>

        <plmt-button
            variant="primary"
            test-id="Save"
            [disabled]="!hasImage && !user?.image_path"
            (clicked)="savePhoto()"
        >
            {{ '$.save' | translate }}
        </plmt-button>
    </main>
</sl-dialog>

<ng-template #customLoadButton>
    <sl-button
        variant="primary"
        outline
        upload
        class="upload-button"
        test-id="Upload"
    >
        {{
            (loadStatus === LoadingStatus.Success
                ? '$.uploadOther'
                : '$.upload'
            ) | translate
        }}
    </sl-button>
</ng-template>
