<sl-badge
    [pill]="pill === ''"
    [pulse]="pulse === ''"
    [class]="size"
    [variant]="variant"
    [class.light]="light === ''"
>
    <div [class.gap-4]="prefixIcon || suffixIcon">
        <sl-icon-container [icon]="prefixIcon"></sl-icon-container>
        <span [class]="size">
            <ng-content></ng-content>
        </span>
        <sl-icon-container [icon]="suffixIcon"></sl-icon-container>
    </div>
</sl-badge>
