<sl-details
    class="custom-icon"
    [summary]="summary"
    [open]="open"
    [disabled]="disabled"
    (show)="show.emit()"
    (hide)="hide.emit()"
>
    <sl-icon
        library="platform"
        name="chevron-right-short"
        slot="expand-icon"
    ></sl-icon>
    <sl-icon
        library="platform"
        name="chevron-down-short"
        slot="collapse-icon"
    ></sl-icon>

    <ng-content></ng-content>
</sl-details>
