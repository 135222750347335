/**
 * NgForTrackByKeyDirective директива позволяет контролировать отслеживание элементов в *ngFor,
 * используя свойство объекта в списке прямо в шаблоне, что обычно делает отслеживание более удобным и требует меньше кода
 *
 * Например, вместо стандартного использования trackBy
 * *ngFor="let item of items; trackBy: trackByFn"
 *
 * можно указать ключ прямо в шаблоне:
 * *ngFor="let item of items; ngForTrackByKey:'id'"
 *
 * где 'id' - это свойство объекта item, по которому Angular будет отслеживать изменения.
 */
import { Directive, inject, Input, NgIterable } from '@angular/core';
import { NgForOf } from '@angular/common';
@Directive({
    selector: '[ngForTrackByKey]',
    standalone: true,
})
export class NgForTrackByKeyDirective<T> {
    @Input() ngForOf!: NgIterable<T>;

    @Input()
    set ngForTrackByKey(ngForTrackBy: keyof T) {
        this.ngFor.ngForTrackBy = (i: number, value: T) => value[ngForTrackBy];
    }

    private ngFor = inject(NgForOf<T>, { self: true });
}
