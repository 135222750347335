import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import { WatchChanges } from 'ng-onpush';
import { FromChild } from 'ng-to-parent';
import { SubscribableComponent } from 'ngx-subscribable';
import { catchError, finalize, Observable, tap } from 'rxjs';

import { FilterTemplateApiService } from '../../../api/filter-template-api.service';
import { TestId } from '../../../directives/test-id.directive';
import { AccessRight } from '../../../enums/access';
import { AccessSettings } from '../../../interfaces/access';
import {
    FilterTemplate,
    FilterTemplateApi,
} from '../../../interfaces/filter-template';
import { ResponseItem } from '../../../interfaces/rest-api';
import { FormState } from '../../../modules/form/form.service';
import { provideControlByName } from '../../../modules/form/provide-control';
import { AlertService } from '../../alert/alert.service';
import { FilterTemplateComponent } from '../filter-template.component';
import { ColumnType } from 'src/shared/enums/dataset';
import { isArray } from 'lodash';

@Component({
    selector: 'core-filter-template-modal',
    templateUrl: './filter-template-modal.component.html',
    styleUrls: ['./filter-template-modal.component.less'],
    providers: [FromChild],
})
export class FilterTemplateModalComponent
    extends SubscribableComponent
    implements OnInit
{
    @Output()
    onSave = new EventEmitter<FilterTemplate>();

    onFilterIsLoaded = new EventEmitter<FilterTemplateApi>();

    @ViewChild('filterTemplate')
    filterTemplateComponent!: FilterTemplateComponent;

    @WatchChanges()
    submitting = false;

    @WatchChanges()
    isOpen = false;

    accessScreen = false;
    canChangeAccess = false;
    onModalClose = new EventEmitter();

    accessSettings: AccessSettings = {
        users: [],
        groups: [],
    };

    get saveButtonText(): string {
        return this.filterTemplateComponent?.form.state === FormState.Update
            ? '$.save'
            : '$.create';
    }

    get testId(): keyof typeof TestId {
        return this.contentSwitcherText === '$.back'
            ? 'Back'
            : 'ShowAccessRights';
    }

    get testIdApply(): keyof typeof TestId {
        return this.saveButtonText === '$.save' ? 'Save' : 'Create';
    }

    get contentSwitcherText(): string {
        return this.accessScreen
            ? '$.back'
            : '_$$.filterTemplate.modal.toAccess';
    }

    constructor(
        private filterTemplateApiService: FilterTemplateApiService,
        private alertService: AlertService,
        private fromChild: FromChild,
        private cdr: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.fromChild
                .listen(provideControlByName)
                .subscribe(({ path, provider }) => {
                    setTimeout(() => {
                        const control =
                            this.filterTemplateComponent?.form.get(path);
                        if (control) provider(control);
                    });
                }),
        ];
    }

    show(filterId?: number): void {
        this.accessSettings = {
            users: [],
            groups: [],
        };

        this.accessScreen = false;
        this.canChangeAccess = false;

        if (!filterId) {
            this.canChangeAccess = true;
            this.onFilterIsLoaded.emit();
            this.cdr.detectChanges();
        } else {
            this.filterTemplateApiService.get(filterId).subscribe(({ row }) => {
                const { groups, users, access_right } = row;

                this.accessSettings = {
                    groups,
                    users,
                };

                this.onFilterIsLoaded.emit(row);
                this.canChangeAccess = access_right === AccessRight.Full;
                this.canChangeAccess = row.access_right === AccessRight.Full;
                this.cdr.detectChanges();
            });
        }

        this.isOpen = true;
    }

    submit(): void {
        this.submitting = true;

        const filterForm = this.filterTemplateComponent?.getMappedForm();

        if (!filterForm) {
            this.submitting = false;
            return;
        }

        const apiMethod = filterForm.id ? 'saveExisted' : 'saveNew';

        const observable: Observable<void | ResponseItem<FilterTemplate>> =
            this.filterTemplateApiService[apiMethod](filterForm);

        const typeSuggest =
            this.filterTemplateComponent.form.value.custom_select
                ?.suggest_column_type;

        if (typeSuggest === ColumnType.Number) {
            if (isArray(filterForm.default_value)) {
                filterForm.default_value = filterForm.default_value.map(
                    (value: string) => +value,
                );
            } else {
                filterForm.default_value = +filterForm.default_value;
            }
        }

        observable
            .pipe(
                tap(() => this.filterTemplateComponent.showNotification()),
                catchError((responseError) => {
                    if (responseError.status === HttpStatusCode.BadRequest) {
                        this.filterTemplateComponent.showServerErrors(
                            responseError.error,
                        );
                    } else {
                        this.alertService.show.emit({
                            responseError,
                            key: `_$$.filterTemplate.${this.filterTemplateComponent.form.state}.error`,
                        });
                    }
                    throw responseError;
                }),
                finalize(() => {
                    this.submitting = false;
                }),
            )
            .subscribe((response) => {
                this.onSave.emit(response?.row);
                this.close();
            });
    }

    close(): void {
        this.isOpen = false;
        this.onModalClose.emit();
    }
}
