import { HttpClient } from '@angular/common/http';
import { InjectionToken, Injector } from '@angular/core';

import { StorageService } from '../../services/storage.service';
import { ApiBaseUrls } from './interfaces';
import { API_SOURCE, scope } from './internal';
import { RestService } from './rest.service';
import { API_BASE_URLS } from './tokens';

const coreInjector = createApiInjector('core');

export const CoreApi = new InjectionToken<RestService>('CoreApi', {
    factory(): RestService {
        return coreInjector.get(RestService);
    },
});

const dashboardInjector = createApiInjector('dashboard');

export const DashboardApi = new InjectionToken<RestService>('DashboardApi', {
    factory(): RestService {
        return dashboardInjector.get(RestService);
    },
});

const storageInjector = createApiInjector('storage');

export const StorageApi = new InjectionToken<RestService>('StorageApi', {
    factory(): RestService {
        return storageInjector.get(RestService);
    },
});

const gatewayInjector = createApiInjector('gateway');

export const GatewayApi = new InjectionToken<RestService>('GatewayApi', {
    factory(): RestService {
        return gatewayInjector.get(RestService);
    },
});

const notificationsInjector = createApiInjector('notifications');

export const NotificationsApi = new InjectionToken<RestService>(
    'NotificationsApi',
    {
        factory(): RestService {
            return notificationsInjector.get(RestService);
        },
    },
);

const logstashInjector = createApiInjector('logstash');

export const LogstashApi = new InjectionToken<RestService>('LogstashApi', {
    factory(): RestService {
        return logstashInjector.get(RestService);
    },
});

const discoveryInjector = createApiInjector('discovery');

export const DiscoveryApi = new InjectionToken<RestService>('DiscoveryApi', {
    factory(): RestService {
        return discoveryInjector.get(RestService);
    },
});

const entityCacheInjector = createApiInjector('entityCache');

export const EntityCacheApi = new InjectionToken<RestService>(
    'EntityCacheApi',
    {
        factory(): RestService {
            return entityCacheInjector.get(RestService);
        },
    },
);

const plainInjector = createApiInjector('' as any);

export const PlainApi = new InjectionToken<RestService>('PlainApi', {
    factory(): RestService {
        return plainInjector.get(RestService);
    },
});

function createApiInjector(
    key: Exclude<keyof ApiBaseUrls, 'project'>,
): Injector {
    return Injector.create({
        providers: [
            { provide: RestService },
            {
                provide: API_BASE_URLS,
                useFactory(): ApiBaseUrls {
                    return scope.API_BASE_URLS;
                },
            },
            {
                provide: API_SOURCE,
                useValue: key,
            },
            {
                provide: HttpClient,
                useFactory(): HttpClient {
                    return scope.httpClient;
                },
            },
            {
                provide: StorageService,
                useFactory(): StorageService {
                    return scope.storageService;
                },
            },
        ],
    });
}
