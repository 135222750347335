import {
    AfterViewInit,
    Directive,
    ElementRef,
    NgZone,
    OnDestroy,
    Renderer2,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { PlmtToggleItemComponent } from '../plmt-toggle-item/plmt-toggle-item.component';
import { PlmtToggleComponent } from '../plmt-toggle.component';

const cssText = `
    position: absolute;
    top: 0;
    bottom: 0;
    margin: var(--sl-spacing-3x-small) 0;
    background-color: var(--plmt-default-white);
    color: var(--plmt-neutral-1000);
    border-radius: var(--sl-border-radius-medium);
    box-shadow: 0px 2px 6px 0px var(--plmt-neutral-shadow);
    transition: transform 0.3s ease;
    z-index: 0 !important;
`;

const mediumCssText = `
    max-height: 32px;
    padding: calc(var(--sl-spacing-2x-small) + 2px) var(--sl-spacing-small);
`;

const smallCssText = `
    max-height: 24px;
    padding: var(--sl-spacing-2x-small) var(--sl-spacing-x-small);
`;

@Directive({
    selector: 'plmt-toggle',
    standalone: true,
})
export class PlmtToggleIndicatorDirective implements AfterViewInit, OnDestroy {
    constructor(
        private component: PlmtToggleComponent,
        private host: ElementRef<HTMLElement>,
        private ngZone: NgZone,
        private renderer: Renderer2,
    ) {}

    private subscriptions: Subscription[] = [];

    private selectedItem: PlmtToggleItemComponent | null = null;

    ngAfterViewInit(): void {
        const indicator: HTMLDivElement = this.renderer.createElement('div');

        this.constructIndicator(indicator);

        this.calculateDefaultIndicatorPosition(indicator);

        this.subscriptions = [
            this.component.selectedItemComponent.subscribe((toggleItem) => {
                if (!toggleItem) return;
                if (this.selectedItem) this.selectedItem.selected = false;

                this.selectedItem = toggleItem;

                this.calculateIndicatorPosition(indicator, toggleItem!.host);
            }),
            this.ngZone.runOutsideAngular(() =>
                fromEvent(window, 'resize').subscribe(() => {
                    if (!this.selectedItem) return;

                    this.calculateIndicatorPosition(
                        indicator,
                        this.selectedItem.host,
                    );
                }),
            ),
        ];
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe(),
        );
    }

    private constructIndicator(indicator: HTMLDivElement): void {
        const wrapper = this.component.host;

        const sizeCssText =
            this.component.size == 'medium' ? mediumCssText : smallCssText;

        indicator.style.cssText = cssText + sizeCssText;

        this.renderer.appendChild(wrapper, indicator);
    }

    private calculateIndicatorPosition<T extends HTMLDivElement>(
        indicator: T,
        itemHost: T,
    ): void {
        this.calculateIndicatorWidth(indicator, itemHost);

        const xStartPoition = this.host.nativeElement.getBoundingClientRect().x;
        const xCurrentItemPosition = itemHost.getBoundingClientRect().x;

        indicator.style.transform = `translateX(${
            xCurrentItemPosition - xStartPoition - 2
        }px)`;
    }

    private calculateIndicatorWidth<T extends HTMLDivElement>(
        indicator: T,
        itemHost: T,
    ): void {
        const padding = this.component.size == 'medium' ? 24 : 16;

        indicator.style.width = itemHost.offsetWidth - padding + 'px';
    }

    private calculateDefaultIndicatorPosition(indicator: HTMLDivElement): void {
        const firstSelectedElement =
            document.querySelector<HTMLDivElement>('.selected');

        if (!firstSelectedElement) return;

        this.calculateIndicatorWidth(indicator, firstSelectedElement);
    }
}
