<sl-tag
    [class.disabled]="disabled"
    [removable]="removable === ''"
    [pill]="pill === ''"
    [variant]="variant"
    [size]="size"
    (sl-remove)="remove.emit()"
>
    <ng-content></ng-content>
</sl-tag>
