import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { SlBadgeSize, SlBadgeVariant } from './model';
import { IconContainerComponent } from '../../components/sl-icon-container/sl-icon-container.component';
import { IconInput } from '../../interfaces/sl-icon.type';

@Component({
    selector: 'plmt-badge',
    templateUrl: './plmt-badge.component.html',
    standalone: true,
    styleUrls: ['./plmt-badge.component.less'],
    imports: [NgIf, IconContainerComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlmtBadgeComponent {
    @Input()
    light: '' | undefined = void 0;

    @Input()
    variant: SlBadgeVariant = 'primary';

    @Input()
    prefixIcon?: IconInput;

    @Input()
    suffixIcon?: IconInput;

    @Input()
    size: SlBadgeSize = 'medium';

    @Input()
    pill: '' | undefined = void 0;

    @Input()
    pulse: '' | undefined = void 0;
}
