import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TrackByIdModule } from 'ng-track-by';
import { ShoelaceModule } from 'shoelace-style-angular';

import { TestIdDirectiveModule } from '../../directives/test-id.directive';
import { TranslateModule } from '../../modules/translate/translate.module';
import {
    ActionDropdownComponent,
    ActionDropdownModule,
} from '../action-dropdown/action-dropdown.module';
import { PaginationModule } from '../pagination/pagination.module';
import { SortIndicatorModule } from '../sort-indicator/sort-indicator.module';
import { TableCheckboxControllerComponent } from './table-checkbox-controller/table-checkbox-controller.component';
import { TableCheckboxControllerDirective } from './table-checkbox-controller/table-checkbox-controller.directive';
import { TableCheckboxComponent } from './table-checkbox/table-checkbox.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { TableSelfCheckboxFilterComponent } from './table-self-checkbox-filter/table-self-checkbox-filter.component';
import { TableSortRoutingComponent } from './table-sort-routing/table-sort-routing.component';
import { TableSortComponent } from './table-sort/table-sort.component';
import { TableSortDirective } from './table-sort/table-sort.directive';
import { TableTextOverflow } from './table-text-overflow.directive';
import { TableComponent } from './table.component';
import { MaxWidthDirective, WidthDirective } from './width.directive';
import { FormModule } from '../../modules/form/form.module';
import { PlmtButtonComponent } from '../../plmt-ui-components';

export * from './table.component';
export * from './table-checkbox/table-checkbox.component';
export * from './table-checkbox-controller/table-checkbox-controller.component';
export * from './table-checkbox-controller/table-checkbox-controller.directive';
export * from './table-filter/table-filter.component';
export * from './table-filter/table-filter.interfaces';
export * from './table-sort/table-sort.component';
export * from './table-sort/table-sort.directive';
export * from './table-sort-routing/table-sort-routing.component';
export * from './table-text-overflow.directive';
export * from './table-self-checkbox-filter/table-self-checkbox-filter.component';
export * from './width.directive';

@NgModule({
    imports: [
        ActionDropdownModule,
        CommonModule,
        FormModule,
        PaginationModule,
        ShoelaceModule,
        SortIndicatorModule,
        TrackByIdModule,
        TranslateModule,
        TestIdDirectiveModule,
        PlmtButtonComponent,
    ],
    exports: [
        ActionDropdownComponent,
        MaxWidthDirective,
        TableComponent,
        TableCheckboxComponent,
        TableCheckboxControllerComponent,
        TableCheckboxControllerDirective,
        TableFilterComponent,
        TableSortComponent,
        TableSortDirective,
        TableSortRoutingComponent,
        TableSelfCheckboxFilterComponent,
        TableTextOverflow,
        WidthDirective,
    ],
    declarations: [
        MaxWidthDirective,
        TableComponent,
        TableCheckboxComponent,
        TableCheckboxControllerComponent,
        TableCheckboxControllerDirective,
        TableFilterComponent,
        TableSortComponent,
        TableSortDirective,
        TableSortRoutingComponent,
        TableSelfCheckboxFilterComponent,
        TableTextOverflow,
        WidthDirective,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TableModule {}
