import { HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { cloneDeep, isEqual } from 'lodash';
import { not } from 'logical-not';
import { WatchChanges } from 'ng-onpush';
import { FromChild } from 'ng-to-parent';
import { SubscribableComponent } from 'ngx-subscribable';
import { catchError, of } from 'rxjs';

import { SlChangeEvent } from '@shoelace-style/shoelace';
import { isNullOrUndefined } from 'src/shared/helpers/is-null-or-undefined';
import {
    DatasetApiService,
    RequestDatasetList,
} from '../../../api/dataset-api.service';
import { FilterTemplateApiService } from '../../../api/filter-template-api.service';
import { CustomColumnIdType } from '../../../enums/custom-column-id-type';
import { ColumnType, DatasetState } from '../../../enums/dataset';
import { FilterMethod } from '../../../enums/filter';
import { FilterTemplateType } from '../../../enums/filter-template-type';
import { FilterTemplateUIType } from '../../../enums/filter-ui-type';
import { DefaultValueAggregation } from '../../../enums/radio-min-max-state.enum';
import { SortDirection } from '../../../enums/sort';
import { FilterUIType } from '../../../enums/types';
import { DataOptionFilter } from '../../../interfaces/data-option';
import { Column, Dataset, DatasetListItem } from '../../../interfaces/dataset';
import {
    FilterTemplateCustomData,
    FilterTemplateCustomRow,
    FilterTemplateDataset,
} from '../../../interfaces/filter-template';
import { DataSearchProvider } from '../../../interfaces/rest-api';
import { Form, FormService } from '../../../modules/form/form.service';
import { FilterTemplateService } from '../../../services/filter-template.service';
import { validate } from '../../../tools/validate';
import { valueOf } from '../../../tools/value-of';
import { realNumbersValidator } from '../../../validators/real-numbers.validator';
import { AlertService } from '../../alert/alert.service';
import {
    FilterValueState,
    checkIfHasDefaultValue,
} from '../../filter-value/filter-value.module';
import { LocalFiltersComponent } from '../../local-filters/local-filters.component';
import { LocalFiltersService } from '../../local-filters/services/local-filters.service';
import { applyChanges, resetToOldForm } from '../../local-filters/tools/consts';
import {
    TreeSelectConfig,
    TreeViewService,
} from '../../tree-view/tree-view.module';
import { FilterTemplateForm } from '../form/filter-template-form';
import { createCustomSelectControl } from '../form/form-helper';

enum SettingsBlock {
    Dataset = 1,
    Custom,
}

@Component({
    selector: 'plmt-extended-settings',
    templateUrl: './extended-settings.component.html',
    styleUrls: [
        '../filter-template.component.less',
        './extended-settings.component.less',
    ],
    providers: [FromChild],
})
export class ExtendedSettingsComponent
    extends SubscribableComponent
    implements OnInit
{
    @Input()
    filterMethods!: FilterMethod[];

    readonly SettingsBlock = SettingsBlock;
    readonly SortDirection = SortDirection;
    readonly ColumnType = ColumnType;
    readonly FilterTemplateUIType = FilterTemplateUIType;
    readonly FilterTemplateType = FilterTemplateType;

    @WatchChanges()
    dataset?: Dataset;

    @WatchChanges()
    column?: Column;

    @WatchChanges()
    sortColumn?: Column;

    @WatchChanges()
    filterMethod: FilterMethod | null = null;

    @WatchChanges()
    settingsBlock = SettingsBlock.Dataset;

    @WatchChanges()
    filterValueState: FilterValueState = { value: null, hasValue: false };

    @ViewChild(LocalFiltersComponent)
    localFiltersComponent!: LocalFiltersComponent;

    filterValueType: FilterUIType | null = null;

    defaultValues: (string | number)[] = [];
    canShowMinMax = false;
    defaultFilterTemplateCustomRow: FilterTemplateCustomRow = {
        label: '',
    };

    columnTypes = [ColumnType.Number, ColumnType.String];

    readonly datasetLoader: DataSearchProvider<DatasetListItem> = (
        name: string,
        offset = 0,
    ) => {
        const params: RequestDatasetList = {
            name,
            offset,
            state: DatasetState.Done,
        };

        return this.datasetApiService.list(params).pipe(
            catchError((responseError) => {
                this.alertService.show.emit({
                    responseError,
                    key: '_$$.filterTemplate.errors.getDatasets',
                });
                return of({ rows: [], total: 0 });
            }),
        );
    };

    formInstance!: FilterTemplateForm;

    get type(): FilterTemplateType {
        return this.formInstance.controls.type.value;
    }

    get subType(): FilterTemplateUIType {
        return this.formInstance.controls.subType.value;
    }

    get customSelectForm(): Form<FilterTemplateCustomData> {
        return this.formInstance.controls.customSelect;
    }

    get customValues(): FormArray {
        return this.customSelectForm.controls.values as FormArray;
    }

    get filtersValues(): DataOptionFilter[] {
        return this.formInstance.controls.filters.value as DataOptionFilter[];
    }

    get treeSelectConfig(): TreeSelectConfig {
        return cloneDeep(
            this.formInstance.controls.treeSelect.value as TreeSelectConfig,
        );
    }

    get keyColumnId(): number | undefined {
        return this.formInstance.controls.datasetSelect.value?.key_column_id;
    }

    get canShowFilterValue(): boolean {
        const isTreeFilter =
            this.formInstance.controls.subType.value ===
            FilterTemplateUIType.TreeView;

        const minimumCondition = Boolean(
            this.settingsBlock === SettingsBlock.Dataset && this.dataset,
        );

        return isTreeFilter
            ? minimumCondition
            : minimumCondition && Boolean(this.column);
    }

    get sortSettings(): Pick<
        FilterTemplateDataset,
        'order_by' | 'order_direction'
    > {
        const { order_by, order_direction } =
            this.formInstance.controls.datasetSelect.controls;

        return {
            order_by: order_by.value,
            order_direction: order_direction.value || undefined,
        };
    }

    constructor(
        private datasetApiService: DatasetApiService,
        private localFiltersService: LocalFiltersService,
        private filterTemplateApiService: FilterTemplateApiService,
        private formService: FormService,
        private filterTemplateService: FilterTemplateService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private treeViewService: TreeViewService,
        fromChild: FromChild,
    ) {
        super();

        this.subscriptions = [
            fromChild.listen(applyChanges).subscribe((_) => {
                this.formInstance.controls.filters.clear();

                if (
                    this.localFiltersService.form.value.length &&
                    !isNullOrUndefined(
                        this.formInstance.controls.defaultValue.value,
                    )
                ) {
                    this.resetDefaultValue();
                }

                cloneDeep(this.localFiltersComponent.form).controls.forEach(
                    (item) => {
                        this.formInstance.controls.filters.push(item as any);
                    },
                );

                this.treeViewService.localFilters.next(this.filtersValues);
            }),

            fromChild.listen(resetToOldForm).subscribe(() => {
                this.localFiltersComponent.filters =
                    this.formInstance.controls.filters.getRawValue();
            }),
        ];
    }

    ngOnInit() {
        this.formInstance = FilterTemplateForm.getInstance(this.formService);

        const keyTypeChanges =
            this.customSelectForm.controls.key_column_type!.valueChanges;

        const columnTypeChanges =
            this.customSelectForm.controls.suggest_column_type!.valueChanges;

        this.subscriptions = [
            this.formInstance.controls.type.valueChanges.subscribe(() => {
                this.formInstance.form.patchValue({ action: undefined });

                this.resetDefaultValue();
                this.canShowMinMax = this.filterTemplateService.canShowMinMax(
                    this.column?.base_type,
                    this.subType,
                    this.type,
                );

                this.defaultValues = this.getCustomSelectDefaultValues();
            }),

            this.formInstance.controls.action.valueChanges.subscribe(
                (filterMethod: FilterMethod) => {
                    if (not(filterMethod)) this.resetDefaultValue();

                    this.filterMethod = filterMethod;

                    this.changeFilterValueType(filterMethod);
                },
            ),

            this.formInstance.controls.subType.valueChanges.subscribe(
                (subType) => {
                    this.formInstance.controls.viewColumnId.updateValueAndValidity();

                    if (subType === FilterTemplateUIType.TreeView) {
                        this.settingsBlock = SettingsBlock.Dataset;

                        this.formInstance.controls.customSelect.reset();
                    }
                },
            ),

            this.formInstance.controls.hasDefaultValue.valueChanges.subscribe(
                (hasValue) => {
                    const value = this.formInstance.controls.defaultValue.value;

                    this.updateValueState(value, hasValue);
                },
            ),

            this.formInstance.controls.defaultValue.valueChanges.subscribe(
                (value) => {
                    const hasValue =
                        this.formInstance.controls.hasDefaultValue.value;

                    this.updateValueState(value, hasValue);
                },
            ),

            keyTypeChanges.subscribe((value) => {
                this.addOrRemoveValidatorsForCustomValues(
                    CustomColumnIdType.Key,
                    'key_column_type',
                );

                this.clearCustomValuesByKey(CustomColumnIdType.Key, value);
                this.cdr.detectChanges();
            }),

            columnTypeChanges.subscribe((value) => {
                this.addOrRemoveValidatorsForCustomValues(
                    CustomColumnIdType.Label,
                    'suggest_column_type',
                );

                this.clearCustomValuesByKey(CustomColumnIdType.Label, value);
                this.cdr.detectChanges();
            }),

            this.customValues.valueChanges.subscribe(() => {
                this.defaultValues = this.getCustomSelectDefaultValues();

                if (
                    this.defaultValues.every(
                        (item) =>
                            !isEqual(
                                item,
                                this.formInstance.controls.defaultValue.value,
                            ),
                    )
                ) {
                    this.formInstance.controls.defaultValue.reset();
                }
            }),

            validate({
                control: this.customSelectForm.controls.name_key_column,

                depends: [this.customValues],

                error: (control) => {
                    const values: FilterTemplateCustomRow[] =
                        this.customValues.getRawValue();

                    return values?.some((item) => item.key)
                        ? Validators.required(control)
                        : null;
                },
            }),

            validate({
                control: this.customSelectForm.controls.name_suggest_column,

                error: (control) => {
                    return this.settingsBlock === SettingsBlock.Custom
                        ? Validators.required(control)
                        : null;
                },
            }),

            validate({
                control: this.formInstance.controls.datasetId,

                error: (control) => {
                    return this.settingsBlock === SettingsBlock.Dataset
                        ? Validators.required(control)
                        : null;
                },
            }),

            validate({
                control: this.formInstance.controls.viewColumnId,

                error: (viewColumnIdControl) => {
                    return this.settingsBlock === SettingsBlock.Dataset &&
                        this.subType !== FilterTemplateUIType.TreeView
                        ? Validators.required(viewColumnIdControl)
                        : null;
                },
            }),
        ];

        this.init();
    }

    toggleSelect(settingsBlock: SettingsBlock): void {
        if (this.settingsBlock === settingsBlock) return;

        this.settingsBlock = settingsBlock;

        this.formInstance.controls.datasetId.reset();
        this.customSelectForm.reset();

        this.dataset = undefined;
        this.column = undefined;

        this.resetDefaultValue();

        switch (settingsBlock) {
            case SettingsBlock.Dataset:
                this.formInstance.controls.datasetSelect.patchValue({
                    order_direction: SortDirection.Asc,
                });

                this.formInstance.controls.viewColumnId.updateValueAndValidity();

                this.clearCustomValues();

                break;
            case SettingsBlock.Custom:
                this.formInstance.controls.datasetSelect.reset();

                this.setDefaultValuesToCustomDataTypes();

                if (!this.customValues.value.length) {
                    this.addCustomSelectItem();
                }

                break;
        }
    }

    onSelectDataset({ id }: Dataset): void {
        this.datasetApiService.get(id).subscribe({
            next: (dataset) => {
                this.dataset = dataset;

                this.column = undefined;

                this.formInstance.controls.datasetSelect.reset();
                this.formInstance.controls.viewColumnId.reset();
                this.formInstance.controls.action.reset();
            },
            error: (response: HttpErrorResponse) => {
                this.alertService.show.emit({
                    responseError: response,
                    key: '_$$.filterTemplate.errors.getDatasetColumns',
                });
            },
        });
    }

    onTreeSelectValueChange(value: any[]): void {
        const hasDefaultValue = checkIfHasDefaultValue(value);
        this.formInstance.controls.hasDefaultValue.patchValue(hasDefaultValue);
    }

    addCustomSelectItem(value?: FilterTemplateCustomRow): void {
        const control = createCustomSelectControl(this.customSelectForm, value);

        this.customValues.push(control);
    }

    deleteCustomSelectItem(i: number): void {
        this.customValues.removeAt(i);
    }

    onDatasetColumnSelected(event: SlChangeEvent): void {
        this.changeColumn(valueOf(event));
        this.resetDefaultValue();
    }

    onSortColumnSelected(event: SlChangeEvent): void {
        this.sortColumn = this.getColumn(valueOf(event));
    }

    onFilterStateChange(state: FilterValueState): void {
        this.formInstance.controls.defaultValue.patchValue(state.value);
        this.formInstance.controls.hasDefaultValue.patchValue(state.hasValue);

        this.formInstance.form.patchValue({
            default_value: state.value,
            has_default_value: state.hasValue,
            original_default_value: state.value,
        });
    }

    onRadioMinMaxChange(state: DefaultValueAggregation): void {
        this.formInstance.controls.defaultValueAggFn.patchValue(state);
    }

    isColumnResultError(column: Column): boolean {
        if (!column.calculate) return false;

        return Boolean(column.calculate.error);
    }

    private init(): void {
        const filterId = this.formInstance.controls.id.value;

        if (!filterId) return;

        const datasetId = this.formInstance.controls.datasetId.value;
        const localFilters = cloneDeep(
            this.formInstance.controls.filters.value as DataOptionFilter[],
        );
        const customSelect = this.formInstance.controls.customSelect.value;
        const datasetSelect = this.formInstance.controls.datasetSelect.value;
        const action = this.formInstance.controls.action.value;

        this.filterValueState = {
            value: this.formInstance.controls.defaultValue.value,
            hasValue: this.formInstance.controls.hasDefaultValue.value,
        };

        this.filterTemplateApiService.get(filterId).subscribe(({ row }) => {
            const filters = row.filter.tree_select?.filters;

            if (!filters) return;

            this.treeViewService.localFilters.next(filters);
        });

        if (datasetId) {
            this.loadDataset(datasetId);
        }

        this.formInstance.controls.filters.clear();

        this.localFiltersService
            .addFilters(localFilters, false)
            .forEach((filter) => {
                this.formInstance.controls.filters.push(filter);
            });

        if (datasetSelect.view_column_id) {
            this.settingsBlock = SettingsBlock.Dataset;
        }

        if (customSelect.name_suggest_column) {
            this.settingsBlock = SettingsBlock.Custom;

            const uniqValues = new Set(
                customSelect.values?.map((item) => item.label),
            );

            this.defaultValues = [...uniqValues];

            const isEqualCustomValues = isEqual(
                customSelect.values,
                this.customSelectForm.get('values')?.value,
            );

            if (!isEqualCustomValues) {
                customSelect.values?.forEach((item) =>
                    this.addCustomSelectItem(item),
                );
            }
        }

        if (action) {
            this.filterMethod = action as any;
        }
    }

    private loadDataset(datasetId: number): void {
        const filterId: number = (this.formInstance.form.getRawValue() as any)
            .id;

        this.filterTemplateApiService
            .getDataset(filterId, datasetId)
            .subscribe({
                next: (dataset) => {
                    this.dataset = dataset;

                    this.changeColumn(
                        this.formInstance.controls.datasetSelect.getRawValue()
                            .view_column_id,
                    );
                },
                error: (response: HttpErrorResponse) => {
                    this.alertService.show.emit({
                        responseError: response,
                        key: '_$$.filterTemplate.errors.getDatasetColumns',
                    });
                },
            });
    }

    private changeColumn(id: number): void {
        this.column = this.getColumn(id);

        this.canShowMinMax = this.filterTemplateService.canShowMinMax(
            this.column?.base_type,
            this.subType,
            this.type,
        );
    }

    private getColumn(id: number): Column | undefined {
        return this.dataset?.columns.find((item) => item.id === id);
    }

    private changeFilterValueType(filterMethod: FilterMethod): void {
        switch (filterMethod) {
            case FilterMethod.Equal:
            case FilterMethod.NotEqual:
                this.filterValueType = FilterUIType.SelectSuggest;
                break;
            case FilterMethod.InList:
            case FilterMethod.NotInList:
                this.filterValueType = FilterUIType.SelectMulti;
                break;
            default:
                this.filterValueType = null;
        }
    }

    private resetDefaultValue(): void {
        this.filterTemplateService.resetDefaultValue();
        this.defaultValues = [];
    }

    private updateValueState(value: any, hasValue: boolean): void {
        this.filterValueState = {
            value,
            hasValue,
        };
    }

    private getCustomSelectDefaultValues(): string[] {
        const uniqValues: Set<string> = new Set(
            this.customValues.value
                .filter((row: Partial<FilterTemplateCustomRow>) => row.label)
                .map(
                    (row: Partial<FilterTemplateCustomRow>) => row.label || '',
                ),
        );

        return [...uniqValues];
    }

    private addOrRemoveValidatorsForCustomValues(
        valuesControlName: string,
        typeControlName: string,
    ): void {
        const valuesControls = this.customValues.controls;
        const type = this.customSelectForm.get(typeControlName)?.value;

        valuesControls.forEach((group) => {
            const control = (group as FormGroup).controls[valuesControlName];

            if (type === ColumnType.Number) {
                control.addValidators(realNumbersValidator);
            } else {
                control.removeValidators(realNumbersValidator);
            }

            control.updateValueAndValidity();
        });
    }

    private clearCustomValuesByKey(
        key: string,
        columnType: ColumnType | undefined,
    ): void {
        if (columnType === ColumnType.String) {
            return;
        }

        this.customValues.controls.forEach((group) => {
            const control = (group as FormGroup).controls[key];
            control.reset();
            control.updateValueAndValidity();
        });
    }

    private setDefaultValuesToCustomDataTypes(): void {
        this.customSelectForm.controls.suggest_column_type?.setValue(
            ColumnType.String,
        );

        this.customSelectForm.controls.key_column_type?.setValue(
            ColumnType.String,
        );
    }

    private clearCustomValues(): void {
        (this.customSelectForm.controls.values as FormArray).clear();
    }
}
