<div class="center">
    <plmt-breadcrumbs [breadcrumbs]="breadcrumbs"></plmt-breadcrumbs>
</div>
<div class="center">
    <div class="content center gap-4">
        <h1>
            {{ title }}
        </h1>
        <plmt-badge *ngIf="counter" light pill size="large" variant="primary">
            {{ counter }}
        </plmt-badge>

        <div *ngIf="extra" class="extra center">
            <ng-container *ngTemplateOutlet="extra"></ng-container>
        </div>
    </div>

    <div class="controls center">
        <ng-content></ng-content>
    </div>
    <div class="nav-user-container">
        <plmt-notification></plmt-notification>
        <plmt-profile></plmt-profile>
    </div>
</div>
