<div class="icon" (click)="slDrawer.show()">
    <sl-icon-button test-id="Notifications" [name]="iconName"></sl-icon-button>

    <plmt-badge
        *ngIf="untouchedValue && isShowBadge"
        variant="warning"
        pill
        size="x-small"
    >
        {{ untounchedBadgeLabel }}
    </plmt-badge>
</div>

<sl-drawer #slDrawer [(open)]="isOpen" (show)="init()">
    <div *ngIf="state === NotificationState.Main" class="main">
        <div class="main header">
            <span>
                {{ '_$.navigation.notification.title.main' | translate }}
            </span>

            <sl-icon-button
                name="gear"
                (click)="state = NotificationState.Settings"
            ></sl-icon-button>
        </div>

        <sl-tab-group
            *ngIf="!loadingValue && messages.total > 0"
            (sl-tab-show)="onTabChange($any($event))"
        >
            <sl-tab slot="nav" panel="all">
                {{ '_$.navigation.notification.tab.all' | translate }}
            </sl-tab>

            <sl-tab slot="nav" panel="untouched">
                {{ '_$.navigation.notification.tab.untouched' | translate }}
            </sl-tab>

            <sl-tab-panel name="all">
                <plmt-messages
                    *ngIf="!messagesLoading"
                    [items]="messages"
                    [loadMore]="loadMoreMessages"
                    (touched)="touch($event)"
                    (delete)="remove($event, messages)"
                ></plmt-messages>

                <lib-spinner [show]="messagesLoading"></lib-spinner>
            </sl-tab-panel>

            <sl-tab-panel name="untouched">
                <plmt-messages
                    *ngIf="!untouchedLoading && untouchedMessages.total > 0"
                    [items]="untouchedMessages"
                    [loadMore]="loadMoreUntouched"
                    (touched)="touch($event)"
                    (delete)="remove($event, untouchedMessages)"
                ></plmt-messages>

                <plmt-empty-state
                    *ngIf="!untouchedLoading && untouchedMessages.total === 0"
                    description="_$.navigation.notification.empty.untouched"
                ></plmt-empty-state>

                <lib-spinner [show]="untouchedLoading"></lib-spinner>
            </sl-tab-panel>
        </sl-tab-group>

        <plmt-empty-state
            *ngIf="!loadingValue && messages.total === 0"
            description="_$.navigation.notification.empty"
            style="margin-bottom: 156px"
        ></plmt-empty-state>

        <lib-spinner [show]="loadingValue"></lib-spinner>
    </div>

    <div
        class="main main-settings"
        *ngIf="state === NotificationState.Settings"
    >
        <div class="header settings">
            <sl-icon-button name="arrow-left" (click)="init()"></sl-icon-button>

            <span>
                {{ '_$.navigation.notification.title.settings' | translate }}
            </span>
        </div>

        <lib-settings (onClear)="clear()" (onBack)="init()"></lib-settings>
    </div>
</sl-drawer>
