import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';
import { TestIdDirectiveModule } from '../../directives/test-id.directive';

import { TranslateModule } from '../../modules/translate/translate.module';
import { ModalCloseConfirmComponent } from './modal-close-confirm.component';
import { PlmtButtonComponent } from '../../plmt-ui-components';

export * from './modal-close-confirm.component';
export * from './modal-close-confirm.service';

@NgModule({
    imports: [
        ShoelaceModule,
        TranslateModule,
        TestIdDirectiveModule,
        PlmtButtonComponent,
    ],
    exports: [ModalCloseConfirmComponent],
    declarations: [ModalCloseConfirmComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalCloseConfirmModule {}
