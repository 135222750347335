<div class="container" [hidden]="accessScreen">
    <core-filter-template
        #filterTemplate
        *ngIf="isOpen"
        [onFilterIsLoaded]="onFilterIsLoaded"
        [onModalClose]="onModalClose"
    ></core-filter-template>
</div>

<core-access
    [settings]="accessSettings"
    [class.hidden]="!accessScreen"
></core-access>

<div class="footer">
    <plmt-button
        *ngIf="canChangeAccess"
        variant="primary"
        [test-id]="testId"
        clear
        (clicked)="accessScreen = !accessScreen"
    >
        {{ contentSwitcherText | translate }}
    </plmt-button>

    <div class="controls" [class.hide]="accessScreen">
        <plmt-button
            variant="neutral"
            test-id="Cancel"
            outline
            (clicked)="isOpen = false"
        >
            {{ '$.cancel' | translate }}
        </plmt-button>

        <plmt-button
            variant="primary"
            [test-id]="testIdApply"
            [loading]="submitting"
            (clicked)="submit()"
        >
            {{ saveButtonText | translate }}
        </plmt-button>
    </div>
</div>
