import { NgFor, NgIf } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ContentChildren,
    CUSTOM_ELEMENTS_SCHEMA,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { SubscribableComponent } from 'ngx-subscribable';
import { BehaviorSubject } from 'rxjs';
import { NgForTrackByKeyDirective } from '../../directives/track-by-key.directive';
import { IconInput } from '../../interfaces/sl-icon.type';
import { TranslateModule } from '../../modules/translate/translate.module';
import { getHost } from '../../tools/get-host';
import { PlmtToggleIndicatorDirective } from './directives/plmt-toggle-indicator.directive';
import { PlmtToggleItemComponent } from './plmt-toggle-item/plmt-toggle-item.component';

export type ToggleSize = 'small' | 'medium';

export interface ToggleItem {
    prefix?: IconInput;
    suffix?: IconInput;
    maxWidth?: number;
    selected?: boolean;
    text?: string;
    callback: VoidFunction;
}

/**
 * ## PlmtToggleComponent
 * Компонент выглядит следующим образом
 *
 * ![Getting Started](./img/toggle-component.png)
 *
 * Компонент создан с логикой множественного переключения toggles.
 *
 * Он имеет несколько вариантов использования в зависимости от контекста выполняемой задачи
 *
 * * ### Вариант №1 - Массив элементов
 *
 * Вы можете создать массив элементов (toggles), каждый из которых будет иметь тип `ToggleItem`
 * и передать его как `Input` параметр в этот компонент
 *
 * При таком варианте использования компонент будет выглядеть следующим образом
 * @component
 * @example
 * ```html
 * <plmt-toggle [toggles]="toggles" (onToggle)="fn($event)"></plmt-toggle>
 * ```
 * **Параметры доступные при таком варианте использования**
 *
 *  @param {ToggleItem[]} toggles - массив конфигураций элементов
 *  @param {EventEmitter<ToggleItem>} onToggle - эммитер, который при выборе нового элемента отдает выбранный toggle
 *
 * * ### Вариант №2 - Проецирование внутрь компонента
 * 
 *  При таком варианте использования недоступны `Input - toggles` и `Output - onToggle`
 * 
 *  И он будет выглядеть следующим образом
 *  @component
 *  @example
 *  ```html
 *  <plmt-toggle>
        <plmt-toggle-item
            *ngFor="let toggle of toggles"
            [prefix]="toggle.prefix"
            [suffix]="toggle.suffix"
            [maxWidth]="toggle.maxWidth"
            [selected]="toggle.selected || false"
            (onChooseToggle)="fn($event)"
        >
            {{ toggle.text! | translate }}
        </plmt-toggle-item>
    </plmt-toggle>
 *  ```
 *  ### Примечание
 *  По дефолту в массиве `toggles` или в `Input - selected` компонента `plmt-toggle-item` не должно быть больше одного выбранного элемента
 */
@Component({
    selector: 'plmt-toggle',
    templateUrl: './plmt-toggle.component.html',
    styleUrls: ['./plmt-toggle.component.less'],
    standalone: true,
    hostDirectives: [PlmtToggleIndicatorDirective],
    imports: [
        NgIf,
        NgFor,
        NgForTrackByKeyDirective,
        PlmtToggleItemComponent,
        TranslateModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlmtToggleComponent
    extends SubscribableComponent
    implements AfterViewInit
{
    @Input()
    toggles: ToggleItem[] = [];

    @HostBinding('class')
    @Input()
    size: ToggleSize = 'medium';

    @Output()
    onToggle = new EventEmitter<ToggleItem>();

    @ViewChildren(PlmtToggleItemComponent)
    private viewToggleItems?: QueryList<PlmtToggleItemComponent>;

    @ContentChildren(PlmtToggleItemComponent)
    private contentToggleItems?: QueryList<PlmtToggleItemComponent>;

    selectedItemComponent = new BehaviorSubject<PlmtToggleItemComponent | null>(
        null,
    );

    readonly host = getHost();

    constructor() {
        super();
    }

    ngAfterViewInit(): void {
        if (this.contentToggleItems?.length) {
            const callback = (toggle: PlmtToggleItemComponent) =>
                this.subscriptions.push(
                    toggle.onChooseToggle.subscribe(() => {
                        this.selectedItemComponent.next(toggle);
                    }),
                );

            this.chooseSelected(this.contentToggleItems, callback);
        }

        if (this.viewToggleItems?.length) {
            this.chooseSelected(this.viewToggleItems);
        }
    }

    choiceToggle(toggle: ToggleItem, component: PlmtToggleItemComponent): void {
        this.selectedItemComponent.next(component);

        toggle.callback();

        this.onToggle.emit(toggle);
    }

    private chooseSelected<T extends PlmtToggleItemComponent>(
        list: QueryList<T>,
        callback?: (toggle: T) => void,
    ): void {
        list.forEach((toggle) => {
            if (toggle.selected) {
                this.selectedItemComponent.next(toggle);
            }

            callback?.(toggle);
        });

        if (!this.selectedItemComponent.value) {
            const firstToggle = list.get(0)!;

            firstToggle.selected = true;

            this.selectedItemComponent.next(firstToggle);
        }
    }
}
