import { DataOption, DataOptionDataBlock } from '../interfaces/data-option';

export function addHiddenColumnsToDataBlock(dataOptions: DataOption): void {
    const dataBlock = dataOptions.blocks.find((block) => block.key == 'data');

    if (!dataBlock) return;

    removeHiddenColumnsFromDataBlock(dataOptions);

    dataOptions.blocks
        .filter((block) => block.key !== 'data')
        .map((block) => block.columns)
        .reduce((prev, curr) => prev.concat(curr))
        .forEach((column) => {
            const isSameColumn = column.column_id === column.sort?.column_id;
            if (column.sort && !isSameColumn) {
                dataBlock.columns.push({
                    column_id: column.sort.column_id,
                    agg_fn: column.sort.agg_fn,
                    hidden: true,
                });
            }
        });
}

export function removeHiddenColumnsFromDataBlock(
    dataOptions: DataOption,
): void {
    const dataBlock = dataOptions.blocks.find((block) => block.key == 'data');

    if (!dataBlock) return;

    dataBlock.columns = dataBlock.columns.filter((column) => !column.hidden);
}

export function addPathsToBlockColumns(blocks: DataOptionDataBlock[]): void {
    let latestIndex = 1;

    blocks
        .map((block) => block.columns)
        .reduce((prev, curr) => prev.concat(curr))
        .forEach((column) => {
            column.path = String(latestIndex);
            latestIndex++;
        });

    blocks
        .map((block) =>
            block.columns.filter(
                (column) => column.column_id !== column.sort?.column_id,
            ),
        )
        .map((columns) => columns.map((column) => column.sort))
        .reduce((prev, curr) => prev.concat(curr))
        .forEach((sort) => {
            if (sort) {
                sort.path = String(latestIndex);
                latestIndex++;
            }
        });
}
