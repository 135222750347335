import { Component, Input } from '@angular/core';

import { RelatedEntityType } from '../../../enums/related-info-enum';
import { RelatedEntity } from '../../../interfaces/related-info-interface';
import { UserAvatar } from '../../../../../plmt-ui-components/avatar/avatar.module';

enum UIType {
    Text,
    ProjectIcon,
    Avatar,
    AdditionalInfo,
    Dataset,
}

@Component({
    selector: 'core-related-entity',
    templateUrl: './related-entity.component.html',
    styleUrls: ['./related-entity.component.less'],
})
export class RelatedEntityComponent {
    @Input()
    item!: RelatedEntity;

    @Input()
    set uiType(value: any) {
        this.type = this.getUIType(value);
    }

    readonly UIType = UIType;

    type = UIType.Text;

    getUserAvatarProps(): UserAvatar {
        const nameProps = this.item.name.split(' ');

        return {
            last_name: nameProps[0],
            first_name: nameProps[1],
            middle_name: nameProps[2],
            image_path: this.item.icon || '',
            username: this.item.name,
        };
    }

    private getUIType(type: string): UIType {
        switch (type) {
            case RelatedEntityType.HtmlEditor:
            case RelatedEntityType.FlatTable:
            case RelatedEntityType.PivotTable:
            case RelatedEntityType.Project:
                return UIType.ProjectIcon;
            case RelatedEntityType.Users:
                return UIType.Avatar;

            case RelatedEntityType.EntityAccess:
                return UIType.AdditionalInfo;
            //TODO RelatedEntityType.Svg нужно перенести логику отображения svg в библиотеку
            default:
                return UIType.Text;
        }
    }
}
