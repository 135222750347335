import {
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    Input,
    OnInit,
} from '@angular/core';
import { Breadcrumbs } from 'plmt-core-library';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TrackByModule } from 'ng-track-by';
import { IconContainerComponent } from '../../components/sl-icon-container/sl-icon-container.component';
import { IconInput } from '../../interfaces/sl-icon.type';

@Component({
    selector: 'plmt-breadcrumbs',
    templateUrl: './plmt-breadcrumbs.component.html',
    styleUrls: ['./plmt-breadcrumbs.component.less'],
    standalone: true,
    imports: [
        AsyncPipe,
        RouterModule,
        NgIf,
        NgForOf,
        TrackByModule,
        IconContainerComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlmtBreadcrumbsComponent {
    @Input()
    breadcrumbs?: Breadcrumbs;

    @Input()
    prefixIcon?: IconInput;

    @Input()
    suffixIcon?: IconInput;
}
