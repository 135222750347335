import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { SlDialog } from '@shoelace-style/shoelace';
import { WatchChanges } from 'ng-onpush';

import { DependenceApiService } from '../../api/related-info-api.service';

import {
    DataSourceGroup,
    EntityActionType,
    RelatedEntityType,
} from '../../enums/related-info-enum';
import {
    DependenceParams,
    RelatedEntity,
} from '../../interfaces/related-info-interface';

interface DependenciesWithKey {
    key: RelatedEntityType;
    value: RelatedEntity[];
}
interface AffectedEntity {
    id: number;
    name: string;
}

@Component({
    selector: 'core-dependencies-confirm[entityType]',
    templateUrl: './dependencies-confirm.component.html',
    styleUrls: ['./dependencies-confirm.component.less'],
})
export class DeleteConfirmComponent {
    @Input()
    entityType!: RelatedEntityType;

    @Output()
    onOk = new EventEmitter<any[]>();

    //TODELETE  #DeleteLockDataset
    //От лока датасета отказываемся, ждем задачу от аналитики, что бы не стопить задачу PD-2523, добавляю временный функционал
    @WatchChanges()
    isShowPasswordConfirm = false;

    @ViewChild('dialog')
    private dialogRef!: ElementRef<SlDialog>;

    @WatchChanges()
    dependencies: DependenciesWithKey[] = [];
    @WatchChanges()
    hasError = false;
    @WatchChanges()
    isLoading = false;
    @WatchChanges()
    affectedEntity?: AffectedEntity;
    @WatchChanges()
    actionType = EntityActionType.Deleted;

    data: any[] = [];

    readonly RelatedEntityType = RelatedEntityType;
    readonly EntityActionType = EntityActionType;
    readonly DataSourceGroup = DataSourceGroup;

    constructor(private dependenceApiService: DependenceApiService) {}

    show(params: any, affectedEntity?: AffectedEntity): void {
        this.data = Array.isArray(params) ? params : [params];
        this.affectedEntity = affectedEntity;
        this.dependencies = [];
        this.actionType = affectedEntity
            ? EntityActionType.Affected
            : EntityActionType.Deleted;
        this.dialogRef.nativeElement.show();
        this.hasError = false;
        this.isShowPasswordConfirm = false;

        if (this.data.length === 1) {
            this.loadDependencies({
                id: affectedEntity?.id || this.data[0].id,
                actionType: this.actionType,
                entityType: this.entityType,
            });
        }
    }

    submit(): void {
        this.onOk.emit(this.data);

        if (this.data.some((item) => item?.lock_owner_id || item?.lock)) {
            this.isShowPasswordConfirm = true;
        } else {
            this.close();
        }
    }

    close(): void {
        this.dialogRef.nativeElement.hide();
    }

    isMultiple(): boolean {
        return this.data.length > 1;
    }

    hasRelatedEntities(): boolean {
        return this.dependencies.some((item) => item.value.length);
    }

    private loadDependencies(params: DependenceParams): void {
        this.isLoading = true;
        this.dependenceApiService.getDependencies(params).subscribe({
            next: ({ rows }) => {
                const dependencies = [];
                for (let key in rows) {
                    dependencies.push({
                        key: key as RelatedEntityType,
                        value: rows[key as RelatedEntityType],
                    });
                }
                this.isLoading = false;
                this.dependencies = dependencies;
            },
            error: () => {
                this.isLoading = false;
                this.hasError = true;
            },
        });
    }
}
