import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TrackByIdModule } from 'ng-track-by';
import { ShoelaceModule } from 'shoelace-style-angular';

import { CloseParentDialogDirectiveModule } from '../../directives/close-parent-dialog.directive';
import { TestIdDirectiveModule } from '../../directives/test-id.directive';
import { TooltipifyModule } from '../../modules/tooltipify/tooltipify.module';
import { TranslateModule } from '../../modules/translate/translate.module';
import { UsernamePipe, UsernamePipeModule } from '../../pipes/username.pipe';
import { PaginationModule } from '../pagination/pagination.module';
import { AccessRightSelectComponent } from './access-right-select/access-right-select.component';
import { AccessComponent } from './access.component';
import { EntitiesComponent } from './entities/entities.component';
import { PlmtDropdownModule } from '../../plmt-ui-components/plmt-dropdown/plmt-dropdown.module';
import { AvatarModule } from '../../plmt-ui-components/avatar/avatar.module';
import { PlmtTagComponent } from '../../plmt-ui-components';
import { PlmtButtonComponent } from '../../plmt-ui-components';

export * from './access.component';

@NgModule({
    imports: [
        AvatarModule,
        CloseParentDialogDirectiveModule,
        CommonModule,
        PaginationModule,
        ShoelaceModule,
        TestIdDirectiveModule,
        TooltipifyModule,
        TrackByIdModule,
        TranslateModule,
        UsernamePipeModule,
        TestIdDirectiveModule,
        PlmtDropdownModule,
        PlmtButtonComponent,
        PlmtTagComponent,
    ],
    exports: [AccessComponent],
    declarations: [
        AccessComponent,
        AccessRightSelectComponent,
        EntitiesComponent,
    ],
    providers: [UsernamePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccessModule {}
