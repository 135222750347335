<sl-dialog #dialog class="center">
    <div class="header">
        <h1>{{ title }}</h1>

        <p *ngIf="description">
            {{ description }}
        </p>
    </div>

    <div class="content">
        <ng-content></ng-content>
    </div>

    <span slot="footer" class="wrapper" (click)="dialog.hide()">
        <ng-container
            *ngTemplateOutlet="buttonCancel || buttonCancelDefault"
        ></ng-container>
    </span>

    <span #apply slot="footer" class="wrapper">
        <ng-container
            *ngTemplateOutlet="buttonOk || buttonOkDefault"
        ></ng-container>
    </span>
</sl-dialog>

<ng-template #buttonOkDefault>
    <plmt-button variant="danger" [test-id]="testId">
        {{ buttonOkText | translate }}
    </plmt-button>
</ng-template>

<ng-template #buttonCancelDefault>
    <plmt-button variant="neutral" outline test-id="Cancel">
        {{ buttonCancelText | translate }}
    </plmt-button>
</ng-template>
