<core-form [data]="formInstance.controls.treeSelect">
    <ng-container
        *ngFor="
            let control of columnsControl.controls;
            index as i;
            let first = first
        "
    >
        <div
            class="grid tree-column-grid"
            [class.first-column]="first"
            style="--template: 1fr var(--third) 1fr"
        >
            <core-validate class="tree-select">
                <div class="wrapper">
                    <sl-icon-button
                        *ngIf="i > 0"
                        class="trash"
                        name="trash"
                        (click)="removeTreeColumn(i)"
                    ></sl-icon-button>
                    <plmt-select
                        #test
                        hoist
                        class="first-select"
                        type="number"
                        [label]="
                            (first
                                ? '_$$.filterTemplate.extends.view.label'
                                : ''
                            ) | translate
                        "
                        [name]="['columns', i, 'view_id']"
                        [placeholder]="
                            '_$$.filterTemplate.placeholder.selectColumn'
                                | translate
                        "
                        [disabled]="!dataset"
                        (sl-change)="
                            onColumnChange(i, $event, TreeFilterColumnType.View)
                        "
                    >
                        <ng-container
                            *ngTemplateOutlet="selectContent"
                        ></ng-container>
                    </plmt-select>
                </div>
            </core-validate>

            <plmt-select
                hoist
                clearable
                type="number"
                [label]="
                    (first ? '_$$.filterTemplate.extends.sort.label' : '')
                        | translate
                "
                [name]="['columns', i, 'sort.order_by']"
                [placeholder]="
                    '_$$.filterTemplate.placeholder.selectColumn' | translate
                "
                [disabled]="!dataset"
                (sl-change)="
                    onColumnChange(i, $event, TreeFilterColumnType.Sort)
                "
            >
                <sl-icon
                    slot="prefix"
                    [name]="getSortIconName(i)"
                    (click)="changeSortDirection($event, i)"
                ></sl-icon>
                <ng-container *ngTemplateOutlet="selectContent"></ng-container>
            </plmt-select>

            <plmt-select
                hoist
                clearable
                type="number"
                [label]="(first ? '_$$.filterTemplate.key' : '') | translate"
                [name]="['columns', i, 'key_id']"
                [placeholder]="
                    '_$$.filterTemplate.placeholder.selectColumn' | translate
                "
                [disabled]="!dataset"
                (sl-change)="
                    onColumnChange(i, $event, TreeFilterColumnType.Key)
                "
            >
                <ng-container *ngTemplateOutlet="selectContent"></ng-container>
            </plmt-select>
        </div>
        <div
            class="sub-header"
            *ngIf="first && columnsControl.controls.length > 1"
        >
            <span>
                {{ '_$$.filterTemplate.extends.view.hierarchy' | translate }}
            </span>
        </div>
    </ng-container>

    <plmt-button
        variant="neutral"
        size="small"
        clear
        (clicked)="addNewTreeColumn()"
    >
        {{ '_$$.filterTemplate.extends.add.treelevel' | translate }}
    </plmt-button>
</core-form>

<ng-template #selectContent>
    <sl-option
        *ngFor="let column of dataset?.columns"
        tooltipify
        trackById
        [value]="column.id"
        [disabled]="column | isColumnResultUnsaved"
    >
        <ng-container
            *ngTemplateOutlet="columnIcons; context: { $implicit: column }"
        ></ng-container>

        {{ column.name }}
    </sl-option>
</ng-template>

<ng-template #columnIcons let-column>
    <app-calculate-column-icon
        class="calc-column-icon"
        slot="prefix"
        [column]="column"
    ></app-calculate-column-icon>

    <core-column-type-icon
        class="base-type-column-icon"
        slot="prefix"
        [column]="column"
    ></core-column-type-icon>
</ng-template>
