import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header.component';
import {
    PlmtBadgeComponent,
    PlmtBreadcrumbsComponent,
} from '../../plmt-ui-components';
import { NotificationModule } from '../navigation/notification/notification.module';
import { NavigationModule } from '../navigation/navigation.module';

export * from './header.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        PlmtBadgeComponent,
        PlmtBreadcrumbsComponent,
        NavigationModule,
        NotificationModule,
    ],
    exports: [HeaderComponent],
    declarations: [HeaderComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderModule {}
