import { FilterDateOffset } from '../../../interfaces/filter-template';
import { DatePluralPeriod } from '../../../modules/date-plural/enums/date-plural-period';
import { FilterDateOffsetOperator } from '../enums/filter-date-offset';
import { DateFormat } from '../../../enums/global-filter';
import { FilterTemplateUIType } from '../../../enums/filter-ui-type';

const MILLISECONDS_IN_MINUTE = 60000;

export function getDate(
    dateOffset: FilterDateOffset,
    dateFormat: DateFormat,
): string {
    const { operator, period } = dateOffset;
    const offset = Number(dateOffset.offset);

    const today = new Date();

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;

    let result = '';

    switch (period) {
        case DatePluralPeriod.Second:
            result = getDateISO(
                calculation(operator, today.getTime(), offset * second),
                dateFormat,
            );
            break;
        case DatePluralPeriod.Minute:
            result = getDateISO(
                calculation(operator, today.getTime(), offset * minute),
                dateFormat,
            );
            break;
        case DatePluralPeriod.Hour:
            result = getDateISO(
                calculation(operator, today.getTime(), offset * hour),
                dateFormat,
            );
            break;
        case DatePluralPeriod.Week:
            result = getDateISO(
                calculation(operator, today.getTime(), offset * week),
                dateFormat,
            );
            break;
        case DatePluralPeriod.Month:
            const month = getValidMonth(dateOffset, offset);

            result = getDateISO(month, dateFormat);
            break;
        case DatePluralPeriod.Quarter:
            const quarter = getValidMonth(dateOffset, offset * 3);

            result = getDateISO(quarter, dateFormat);
            break;
        case DatePluralPeriod.Year:
            const year = today.setFullYear(
                calculation(operator, today.getFullYear(), offset),
            );

            result = getDateISO(year, dateFormat);
            break;
        default:
            result = getDateISO(
                calculation(operator, today.getTime(), offset * day),
                dateFormat,
            );
    }

    return result;
}

export function getTime(value: any): number {
    return new Date(value).getTime();
}

export function setDateFormat(filterSubType: string): DateFormat {
    switch (filterSubType) {
        case FilterTemplateUIType.DateTimeCalendar:
        case FilterTemplateUIType.DateTimePeriod:
        case FilterTemplateUIType.DateTimeSearch:
        case FilterTemplateUIType.DateTimeSearchPeriod:
            return DateFormat.DateTime;
        default:
            return DateFormat.Date;
    }
}

function calculation(
    operator: FilterDateOffsetOperator,
    a: number,
    b: number,
): number {
    if (operator === FilterDateOffsetOperator.Addition) {
        return a + b;
    }

    return a - b;
}

function getValidMonth(dateOffset: FilterDateOffset, offset = 1): number {
    const { operator } = dateOffset;

    const today = new Date();
    const result = new Date(today);

    const month = calculation(operator, today.getMonth(), offset);

    const monthLastDay = new Date(today.getFullYear(), month + 1, 0);
    const date =
        today.getDate() - monthLastDay.getDate() > 0 && offset !== 0
            ? monthLastDay.getDate()
            : today.getDate();

    result.setDate(date);
    result.setMonth(month);

    return result.getTime();
}

export function getDateISO(value: number, type: DateFormat): string {
    const timezoneOffsetMilliseconds =
        new Date(value).getTimezoneOffset() * MILLISECONDS_IN_MINUTE;

    const dateWithoutSeconds = new Date(value - timezoneOffsetMilliseconds);
    dateWithoutSeconds.setSeconds(0, 0);

    const date = dateWithoutSeconds.toISOString().split('.').shift()!;

    if (type === DateFormat.Date) {
        return date.split('T').shift()!;
    }

    return date;
}
