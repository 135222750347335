export enum RelatedEntityType {
    DatasetJoin = 'dataset-join',
    DatasetLocal = 'dataset-local',
    DatasetColumn = 'dataset-column',
    Datasource = 'datasource',
    Users = 'users',
    Group = 'group',
    Roles = 'roles',
    Theme = 'theme',
    GlobalFilter = 'global-filter',
    EntityAccess = 'entity_access',
    Component = 'component',
    Project = 'dashboard-project',
    Layer = 'dashboard-layer',
    Widget = 'widget',
    FlatTable = 'flat-table',
    PivotTable = 'pivot-table',
    HtmlEditor = 'html-editor',
    SvgOnLayer = 'svg-on-layer',
    Svg = 'svg',
    Report = 'report-dataset',
}
export enum EntityActionType {
    Deleted = 'deleted',
    Affected = 'affected',
}

export enum DataSourceGroup {
    Ad = 'ad',
    Polymatica = 'polymatica',
}
