<div *ngFor="let notification of items.rows" class="item">
    <div class="header">
        <div class="main">
            <app-message-icon [notification]="notification"></app-message-icon>

            <span class="message">
                {{ getTitle(notification) | translate }}
            </span>

            <core-relative-time
                [date]="notification.create_date"
            ></core-relative-time>

            <div [class.untouched]="!notification.is_viewed"></div>
        </div>

        <div class="actions">
            <core-action
                type="delete"
                (click)="delete.emit(notification.id)"
            ></core-action>
        </div>
    </div>

    <div *ngIf="is[EventType.DatasetLoaded](notification)" class="content">
        <div class="text two-lines" multiLineTooltipify>
            {{ notification.event_data.name }}
        </div>

        <div
            *ngIf="notification.event_level !== EventLevel.Error"
            class="actions"
        >
            <plmt-button
                variant="primary"
                size="small"
                test-id="ToDataset"
                light
                (clicked)="
                    goTo(
                        ModuleName.Manager,
                        'dataset/' + notification.event_data.id
                    )
                "
            >
                {{ '_$.navigation.notification.action.toDataset' | translate }}
            </plmt-button>
        </div>
    </div>

    <div *ngIf="is[EventType.DatasetLocalGc](notification)" class="content">
        <div
            *ngIf="notification.event_level !== EventLevel.Error"
            class="text two-lines"
        >
            {{
                '_$.navigation.notification.delete.collection.count' | translate
            }}
            {{ notification.event_data.removed_entities_count }}
        </div>
    </div>

    <div *ngIf="is[EventType.DatasetUpdated](notification)" class="content">
        <div class="text two-lines" multiLineTooltipify>
            {{ notification.event_data.name }}
        </div>

        <div
            *ngIf="notification.event_level !== EventLevel.Error"
            class="actions"
        >
            <plmt-button
                variant="primary"
                size="small"
                test-id="ToDataset"
                light
                (clicked)="
                    goTo(
                        ModuleName.Manager,
                        'dataset/' + notification.event_data.id
                    )
                "
            >
                {{ '_$.navigation.notification.action.toDataset' | translate }}
            </plmt-button>
        </div>
    </div>

    <div *ngIf="is[EventType.ProjectExported](notification)" class="content">
        <div class="text two-lines" multiLineTooltipify>
            {{ notification.event_data.project_name }}
        </div>
        <div class="text two-lines" multiLineTooltipify>
            {{ notification.event_data.filename }}
        </div>

        <div
            *ngIf="notification.event_level !== EventLevel.Error"
            class="actions"
        >
            <plmt-button
                variant="primary"
                size="small"
                test-id="Download"
                light
                (clicked)="download(notification.event_data)"
            >
                {{ '_$.navigation.notification.action.download' | translate }}
            </plmt-button>
        </div>
    </div>

    <div *ngIf="is[EventType.LogsExported](notification)" class="content">
        <div class="text two-lines" multiLineTooltipify>
            {{ notification.event_data.filename }}
        </div>

        <div
            *ngIf="notification.event_level !== EventLevel.Error"
            class="actions"
        >
            <plmt-button
                variant="primary"
                size="small"
                test-id="Download"
                light
                (clicked)="download(notification.event_data)"
            >
                {{ '_$.navigation.notification.action.download' | translate }}
            </plmt-button>
        </div>
    </div>

    <div *ngIf="is[EventType.ReportReady](notification)" class="content">
        <div class="text two-lines" multiLineTooltipify>
            {{ notification.event_data.name }}
        </div>

        <div
            *ngIf="notification.event_level !== EventLevel.Error"
            class="actions"
        >
            <plmt-button
                variant="primary"
                size="small"
                light
                (clicked)="goTo(ModuleName.Reports, '')"
            >
                {{ '_$.navigation.notification.action.toReport' | translate }}
            </plmt-button>

            <plmt-button
                variant="primary"
                size="small"
                test-id="Download"
                light
                (clicked)="download(notification.event_data)"
            >
                {{ '_$.navigation.notification.action.download' | translate }}
            </plmt-button>
        </div>
    </div>

    <div *ngIf="is[EventType.WidgetExported](notification)" class="content">
        <div class="text two-lines" multiLineTooltipify>
            {{ notification.event_data.filename }}
        </div>

        <div
            *ngIf="notification.event_level !== EventLevel.Error"
            class="actions"
        >
            <plmt-button
                variant="primary"
                size="small"
                test-id="Download"
                light
                (clicked)="download(notification.event_data)"
            >
                {{ '_$.navigation.notification.action.download' | translate }}
            </plmt-button>
        </div>
    </div>

    <div *ngIf="is[EventType.EntityExported](notification)" class="content">
        <div class="text two-lines" multiLineTooltipify>
            {{ getFileNameForExport(notification.event_data) }}
        </div>

        <div
            *ngIf="notification.event_level !== EventLevel.Error"
            class="actions"
        >
            <plmt-button
                variant="primary"
                size="small"
                test-id="Download"
                light
                (clicked)="
                    download({
                        storage_path: notification.event_data.storage_path,
                        filename: getFileNameForExport(notification.event_data)
                    })
                "
            >
                {{ '_$.navigation.notification.action.download' | translate }}
            </plmt-button>
        </div>
    </div>

    <div *ngIf="is[EventType.ImportFinished](notification)" class="content">
        <div
            *ngIf="notification.event_data.head_elements"
            class="text two-lines"
        >
            {{ notification.event_data.head_elements | importedEntitiesNames }}
        </div>
        <ng-container
            *ngIf="notification.event_data.not_found_project_links as links"
        >
            <div *ngIf="links.length > 0" class="warning-content">
                <span class="small-font">
                    {{
                        '_$.navigation.notification.error.import_detail_links_error'
                            | translate
                    }}: {{ links | stringArrayToString }}</span
                >
                <span class="small-font warning-text">
                    {{
                        '_$.navigation.notification.error.import_detail_links_error_message'
                            | translate
                    }}
                </span>
            </div>
        </ng-container>
    </div>
</div>

<core-lazy-loading
    *ngIf="items.rows.length < items.total"
    [load]="loadMore"
></core-lazy-loading>
