<div
    *ngIf="breadcrumbs as breadcrumbs"
    class="breadcrumbs flex-vertical-center"
>
    <ng-container
        *ngFor="let item of breadcrumbs.items; last as last"
        trackBy="link"
    >
        <div class="breadcrumbs-element flex-vertical-center">
            <sl-icon-container
                *ngIf="prefixIcon"
                class="mr-8"
                [icon]="prefixIcon"
            ></sl-icon-container>
            <span [routerLink]="item.link" class="crumb link">
                {{ item.label | async }}
            </span>
            <sl-icon-container
                *ngIf="suffixIcon"
                class="ml-8"
                [icon]="suffixIcon"
            ></sl-icon-container>
            <sl-icon
                *ngIf="!(last && !breadcrumbs.target)"
                library="platform"
                name="chevron-right-short"
            ></sl-icon>
        </div>
    </ng-container>

    <ng-container *ngIf="breadcrumbs.target as target">
        <span class="crumb">
            {{ target | async }}
        </span>
    </ng-container>
</div>
