<plmt-button
    clear
    variant="primary"
    size="small"
    prefixIcon="plus-lg"
    (clicked)="filterEdit.open()"
>
    <ng-container *ngIf="form.length === 0">
        {{ '_$$.filters.createFilter' | translate }}
    </ng-container>
    <ng-container *ngIf="form.length > 0">
        {{ '_$$.filters.editFilter' | translate }}
    </ng-container>
</plmt-button>

<core-filter-modal #filterEdit></core-filter-modal>
