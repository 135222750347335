<sl-icon-container
    *ngIf="prefix"
    [icon]="prefix"
    [class.prefix-margin]="span.children.length || suffix"
></sl-icon-container>

<span #span tooltipify [style.maxWidth.px]="maxWidth">
    <ng-content></ng-content>
</span>

<sl-icon-container
    *ngIf="suffix"
    [icon]="suffix"
    [class.suffix-margin]="span.children.length || prefix"
></sl-icon-container>
