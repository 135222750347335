import { ModuleRightsDashboard } from 'dashboard/src/app/enums/module-rights-dashboard';
import { ModuleRightsManager } from 'manager/src/app/enums/module-rights-manager';

import { ModuleName } from '../../enums/module';
import { TestId } from '../../directives/test-id.directive';

type ManagerAccessRights = Record<ModuleName.Manager, ModuleRightsManager[]>;
type DashboardAccessRights = Record<
    ModuleName.Dashboard,
    ModuleRightsDashboard[]
>;

export interface NavigationList {
    label?: string;
    items: NavigationItem[];
}

export interface NavigationItem {
    href: string;
    label: string;
    testId: keyof typeof TestId;
    iconName: string;
    iconLibrary?: string;
    accessRights: Partial<ManagerAccessRights | DashboardAccessRights> | [];
    superUserAccess?: true;
}

export const navigationConfig: NavigationList[] = [
    {
        items: [
            {
                href: '/dataset',
                label: 'nav.menu.datasets',
                testId: 'Datasets',
                iconName: 'database-not-fill',
                iconLibrary: 'core',
                accessRights: {
                    [ModuleName.Manager]: [
                        ModuleRightsManager.DatasetView,
                        ModuleRightsManager.DatasetFullAccess,
                    ],
                },
            },
            {
                href: '/filters',
                label: 'nav.menu.filters',
                testId: 'Filters',
                iconName: 'funnel',
                accessRights: {
                    [ModuleName.Manager]: [ModuleRightsManager.Filters],
                },
            },
            {
                href: '/source-list',
                label: 'nav.menu.sources',
                testId: 'Sources',
                iconName: 'diagram-3',
                accessRights: {
                    [ModuleName.Manager]: [
                        ModuleRightsManager.DatasourceView,
                        ModuleRightsManager.DatasourceFullAccess,
                    ],
                },
            },
        ],
    },
    {
        items: [
            {
                label: 'nav.menu.projects',
                testId: 'Projects',
                iconName: 'grid-1x2',
                href: '/dashboard/project',
                accessRights: {
                    [ModuleName.Dashboard]: [
                        ModuleRightsDashboard.ProjectView,
                        ModuleRightsDashboard.ProjectFullAccess,
                    ],
                },
            },
            {
                label: 'nav.menu.widgets',
                testId: 'Widgets',
                iconName: 'pie-chart',
                href: '/dashboard/widgets',
                accessRights: {
                    [ModuleName.Dashboard]: [
                        ModuleRightsDashboard.WidgetView,
                        ModuleRightsDashboard.WidgetFullAccess,
                    ],
                },
            },
            {
                label: 'nav.menu.components',
                testId: 'Components',
                iconName: 'puzzle',
                href: '/dashboard/components/components',
                accessRights: {
                    [ModuleName.Dashboard]: [ModuleRightsDashboard.Component],
                },
            },
            {
                label: 'nav.menu.svgObjects',
                testId: 'SvgObjects',
                iconName: 'svg',
                href: '/dashboard/components/svg-objects',
                iconLibrary: 'core',
                accessRights: {
                    [ModuleName.Dashboard]: [ModuleRightsDashboard.SvgObjects],
                },
            },
            {
                label: 'nav.menu.themes',
                testId: 'Themes',
                iconName: 'palette',
                href: '/dashboard/components/themes',
                accessRights: {
                    [ModuleName.Dashboard]: [ModuleRightsDashboard.Themes],
                },
            },
        ],
    },
    {
        label: 'nav.menu.settings',
        items: [
            {
                label: 'nav.menu.users',
                testId: 'Users',
                iconName: 'person',
                href: '/setting/user-list',
                accessRights: {
                    [ModuleName.Manager]: [ModuleRightsManager.UserAccess],
                },
            },
            {
                label: 'nav.menu.roles',
                testId: 'Roles',
                iconName: 'person-gear',
                href: '/setting/roles',
                iconLibrary: 'core',
                accessRights: {
                    [ModuleName.Manager]: [ModuleRightsManager.Roles],
                },
            },
            {
                label: 'nav.menu.groups',
                testId: 'Groups',
                iconName: 'people',
                href: '/setting/group-list',
                accessRights: {
                    [ModuleName.Manager]: [ModuleRightsManager.GroupAccess],
                },
            },
            {
                label: 'nav.menu.appearance',
                testId: 'Appearance',
                iconName: 'layout-text-sidebar-reverse',
                href: '/setting/themes',
                accessRights: {
                    [ModuleName.Manager]: [],
                },
                superUserAccess: true,
            },
            {
                label: 'nav.menu.modules',
                testId: 'Modules',
                iconName: 'grid-3x3-gap',
                href: '/setting/modules',
                accessRights: {
                    [ModuleName.Manager]: [ModuleRightsManager.Modules],
                },
            },
            {
                label: 'nav.menu.plugins',
                testId: 'Plugins',
                iconName: 'plug',
                href: '/setting/plugins',
                accessRights: {
                    [ModuleName.Manager]: [ModuleRightsManager.Plugins],
                },
            },
            {
                label: 'nav.menu.logs',
                testId: 'Logs',
                iconName: 'journal-text',
                href: '/setting/logs',
                accessRights: {
                    [ModuleName.Manager]: [ModuleRightsManager.Logs],
                },
            },
        ],
    },
];
