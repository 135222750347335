<div *ngIf="isDisplayMenu" class="menu" [class.open]="isOpen">
    <div class="burger">
        <div class="menu-item menu-item--logo">
            <sl-tooltip
                hoist
                placement="right"
                [disabled]="isOpen"
                [content]="'nav.menu.burger' | translate"
            >
                <sl-icon-button [name]="burgerButton" (click)="toggleMenu()">
                </sl-icon-button>
            </sl-tooltip>

            <lib-logo class="logo"></lib-logo>
        </div>

        <sl-divider></sl-divider>
    </div>

    <div #container class="content">
        <ng-container *ngFor="let module of modules$ | async">
            <div *ngIf="module.items.length" class="modules">
                <h3 *ngIf="module.label" class="module-name">
                    <sl-divider></sl-divider>
                    {{ module.label | translate }}
                </h3>
                <ng-container *ngFor="let item of module.items">
                    <sl-tooltip
                        hoist
                        placement="right"
                        [disabled]="isOpen"
                        [content]="item.label | translate"
                    >
                        <core-navigation-item
                            [href]="item.href"
                            [test-id]="item.testId"
                        >
                            <sl-icon
                                [library]="item.iconLibrary || 'default'"
                                [name]="item.iconName"
                            ></sl-icon>

                            <div class="text">
                                {{ item.label | translate }}
                            </div>
                        </core-navigation-item>
                    </sl-tooltip>
                </ng-container>
            </div>
        </ng-container>

        <div
            *ngIf="isShowMore && navigationMenu.length"
            class="navigation-menu"
        >
            <sl-divider></sl-divider>

            <core-navigation-menu [items]="navigationMenu">
            </core-navigation-menu>
        </div>
    </div>
</div>
