<sl-dialog
    #dialog
    class="center"
    [class.hasRelatedEntities]="hasRelatedEntities()"
>
    <div slot="label">
        <ng-container
            *ngIf="actionType === EntityActionType.Deleted; else affectedTitle"
        >
            {{
                isMultiple()
                    ? ('$$.dependenciesConfirm.plural.title' | translate)
                    : ('$$.dependenciesConfirm.title.' + entityType | translate)
            }}
        </ng-container>
        <ng-template #affectedTitle>
            {{ '$$.dependenciesConfirm.title.affected' | translate }}
        </ng-template>
        <span
            *ngIf="hasRelatedEntities() || isMultiple() || hasError"
            class="attention"
        >
            {{ '$$.dependenciesConfirm.attention' | translate }}
        </span>
    </div>
    <core-spinner [isLoading]="isLoading" [hideContentWhileLoading]="true">
        <ng-container *ngIf="!hasError">
            <ng-container
                *ngIf="isMultiple() && entityType !== RelatedEntityType.Group"
            >
                <div
                    *translate="
                        '$$.dependenciesConfirm.plural.confirm.' + entityType;
                        let key
                    "
                    class="mb-large"
                >
                    <p *ngFor="let line of key.lines">
                        {{ line }}
                    </p>
                </div>
            </ng-container>
            <p
                *ngIf="entityType === RelatedEntityType.Group && isMultiple()"
                class="multi-delete-group-description"
            >
                {{
                    '$$.dependenciesConfirm.confirm.noDependence.multi.top.' +
                        entityType +
                        actionType | translate
                }}
            </p>

            <div
                [class.entity-multiple-container]="isMultiple()"
                [class.project-name]="entityType === RelatedEntityType.Project"
            >
                <ng-container *ngIf="affectedEntity?.name; else deletedEntity">
                    <p class="bold" tooltipify>{{ affectedEntity?.name }}</p>
                </ng-container>

                <ng-template #deletedEntity>
                    <div
                        *ngFor="let item of data"
                        [class.auto-group-delete]="
                            item.source === DataSourceGroup.Ad &&
                            entityType === RelatedEntityType.Group
                        "
                    >
                        <core-related-entity
                            class="bold"
                            [item]="item"
                            [uiType]="entityType"
                        ></core-related-entity>
                        <sl-badge
                            *ngIf="item.source === DataSourceGroup.Ad"
                            variant="primary"
                            [pill]="true"
                        >
                            Auto
                        </sl-badge>
                    </div>
                </ng-template>
            </div>

            <ng-container
                *ngIf="
                    !isMultiple() && entityType === RelatedEntityType.Project
                "
            >
                <p class="multi-delete-description">
                    {{
                        '$$.dependenciesConfirm.confirm.description.' +
                            entityType +
                            actionType | translate
                    }}
                </p>
                <ng-container
                    *translate="
                        '$$.dependenciesConfirm.project.entities';
                        let key
                    "
                >
                    <p class="bold" *ngFor="let line of key.lines">
                        {{ line }}
                    </p>
                </ng-container>
            </ng-container>

            <div *ngIf="isShowPasswordConfirm" class="password-confirm">
                <ng-content select=".password"> </ng-content>
            </div>

            <ng-container *ngIf="hasRelatedEntities()">
                <p>
                    {{
                        '$$.dependenciesConfirm.confirm.description.' +
                            entityType +
                            actionType | translate
                    }}
                </p>

                <div class="entities">
                    <ng-container *ngFor="let row of dependencies">
                        <plmt-details *ngIf="row.value.length" [open]="true">
                            <span slot="summary">
                                {{
                                    '$$.dependenciesConfirm.label.' + row.key
                                        | translate: { count: row.value.length }
                                }}
                            </span>
                            <ng-container *ngFor="let item of row.value">
                                <core-related-entity
                                    [item]="item"
                                    [uiType]="row.key"
                                ></core-related-entity>
                            </ng-container>
                        </plmt-details>
                    </ng-container>
                </div>
            </ng-container>

            <p
                *ngIf="isMultiple() && entityType === RelatedEntityType.Group"
                class="multi-delete-group"
            >
                {{
                    '$$.dependenciesConfirm.confirm.noDependence.multi.bottom.' +
                        entityType +
                        actionType | translate
                }}
            </p>

            <div *ngFor="let group of data">
                <p
                    *ngIf="
                        !isMultiple() &&
                        group.source === DataSourceGroup.Ad &&
                        entityType === RelatedEntityType.Group
                    "
                    class="auto-description"
                >
                    {{
                        '$$.dependenciesConfirm.confirm.noDependence.' +
                            group.source | translate
                    }}
                </p>
            </div>

            <p
                *ngIf="!isMultiple()"
                [class.extra-confirm-spacing]="
                    entityType === RelatedEntityType.Project
                "
            >
                {{
                    (hasRelatedEntities()
                        ? '$$.dependenciesConfirm.confirm.hasDependence.'
                        : '$$.dependenciesConfirm.confirm.noDependence.') +
                        entityType +
                        actionType | translate
                }}
            </p>
        </ng-container>

        <p *ngIf="hasError" class="delete-error">
            {{ '$$.dependenciesConfirm.error' | translate }}
        </p>
    </core-spinner>

    <plmt-button
        class="cancel-button"
        slot="footer"
        variant="neutral"
        test-id="Cancel"
        outline
        (clicked)="close()"
    >
        {{ '$.cancel' | translate }}
    </plmt-button>

    <plmt-button
        slot="footer"
        [clear]="actionType === EntityActionType.Deleted ? '' : undefined"
        [variant]="
            actionType === EntityActionType.Deleted ? 'danger' : 'primary'
        "
        [disabled]="isLoading"
        [test-id]="actionType === EntityActionType.Deleted ? 'Delete' : 'Apply'"
        (clicked)="submit()"
    >
        {{
            (actionType === EntityActionType.Deleted ? '$.delete' : '$.save')
                | translate
        }}
    </plmt-button>
</sl-dialog>
