export enum WidgetType {
    Widget = 'widget',
    Link = 'link',
    Text = 'text',
    Group = 'group',
    Image = 'image',
    Video = 'video',
    Filter = 'filter',
    Table = 'table',
    Svg = 'svg',
}

export enum WidgetTableType {
    PivotTable = 'pivot_table',
    DataTable = 'data_table',
}

export enum WidgetConfigType {
    YandexMap = 'yandex_map',
}
