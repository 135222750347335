<div *ngIf="entities?.length" class="entities">
    <ng-container *ngFor="let entity of entities; let i = index" trackById>
        <ng-container
            *ngTemplateOutlet="
                rowTemplate;
                context: {
                    $implicit: entity,
                    access: selected.get(entity.id)?.access,
                    index: i
                }
            "
        ></ng-container>
    </ng-container>
</div>

<ng-template #rowTemplate let-entity let-access="access" let-index="index">
    <div class="entity" [class.second-column]="index + 1 > limit / 2">
        <sl-checkbox [checked]="!!access" (sl-change)="onToggle(entity)">
            <ng-container
                *ngTemplateOutlet="
                    entityTemplate;
                    context: { $implicit: entity }
                "
            ></ng-container>
        </sl-checkbox>

        <plmt-tag *ngIf="access" variant="neutral">
            <core-access-right-select
                [value]="access.access_right"
                (valueChange)="onAccessRightChange(entity.id, $event)"
            ></core-access-right-select>
        </plmt-tag>
    </div>
</ng-template>

<core-pagination
    [class.hidden]="loading"
    [page]="page"
    [total]="total"
    [limit]="params.limit!"
    (onPageChange)="onPageChange($event)"
></core-pagination>

<div *ngIf="loading" class="spinner-container">
    <sl-spinner></sl-spinner>
</div>

<sl-alert *ngIf="isEmpty" [open]="true">
    <ng-content select=".empty-message"></ng-content>
</sl-alert>

<sl-alert *ngIf="httpError" variant="danger" [open]="true">
    <sl-icon slot="icon" name="exclamation-octagon"></sl-icon>
    <strong>
        <ng-content select=".error-message"></ng-content>
    </strong>

    <div>
        {{
            '_$.access.error'
                | translate
                    : {
                          text: httpError.message,
                          code: httpError.status
                      }
        }}
    </div>
</sl-alert>
