import {
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'plmt-details',
    templateUrl: './plmt-details.component.html',
    styleUrls: ['./plmt-details.component.less'],
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlmtDetailsComponent {
    @Input()
    summary = '';

    @Input()
    open = false;

    @Input()
    disabled = false;

    @Output()
    show = new EventEmitter<boolean>(true);

    @Output()
    hide = new EventEmitter<boolean>(false);
}
