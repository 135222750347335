export const CORE_BASE_URLS = {
    core: '/proxy/manager/api',
    dashboard: '/proxy/dashboard/api',
    discovery: '/proxy/discovery/api',
    entityCache: '/proxy/entity-cache/api',
    gateway: '/proxy/gateway/api',
    logstash: '/proxy/logstash/api',
    notifications: '/proxy/notifications/api',
    storage: '/proxy/file-storage/api',
};
