<sl-dialog
    #dialog
    class="center"
    [label]="'_$.modalCloseConfirm.title' | translate"
    (hide)="close.emit(false)"
>
    {{ '_$.modalCloseConfirm.description' | translate }}

    <plmt-button
        slot="footer"
        variant="neutral"
        test-id="Cancel"
        outline
        (clicked)="dialog.hide()"
    >
        {{ '$.cancel' | translate }}
    </plmt-button>

    <plmt-button
        slot="footer"
        variant="primary"
        test-id="Exit"
        (clicked)="close.emit(true); dialog.hide()"
    >
        {{ '_$.modalCloseConfirm.ok' | translate }}
    </plmt-button>
</sl-dialog>
