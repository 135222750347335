export enum TagSize {
    'small',
    'medium',
    'large',
}
export type TagVariant =
    | 'neutral'
    | 'primary'
    | 'success'
    | 'warning'
    | 'danger';
