import {
    Component,
    HostBinding,
    Input,
    OnInit,
    TemplateRef,
} from '@angular/core';
import { Observable } from 'rxjs';

export interface Breadcrumbs {
    items: {
        label: Observable<string>;
        link: string;
    }[];

    target?: Observable<string>;
}

@Component({
    selector: 'core-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})
export class HeaderComponent {
    @Input()
    title = '';

    @Input()
    counter = 0;

    @HostBinding('class.has-breadcrumbs')
    @Input()
    breadcrumbs?: Breadcrumbs;

    @Input()
    extra?: TemplateRef<any>;
}
