<plmt-button
    class="main-button"
    variant="neutral"
    outline
    size="medium"
    (clicked)="showModal()"
>
    <sl-icon name="search"></sl-icon>
</plmt-button>
<sl-icon-button
    *ngIf="isApplied"
    name="x"
    class="badge"
    (click)="reset()"
></sl-icon-button>

<sl-dialog
    #dialog
    class="center"
    [(open)]="isOpen"
    [label]="label | translate"
    (sl-after-show)="isShown = true"
    (sl-after-hide)="isShown = false"
>
    <core-form [data]="form">
        <div class="grid-container">
            <core-validate>
                <sl-input
                    clearable
                    autofocus
                    name="search"
                    [placeholder]="
                        '_$.tableFilter.search.placeholder' | translate
                    "
                >
                    <sl-icon
                        library="core"
                        name="search-bold"
                        slot="prefix"
                    ></sl-icon>
                </sl-input>
            </core-validate>

            <plmt-button variant="primary" test-id="Apply" (clicked)="apply()">
                {{ '$.find' | translate }}
            </plmt-button>
        </div>
        <div *ngIf="isApplied && isOpen" class="footer">
            <plmt-button
                test-id="Reset"
                variant="neutral"
                outline
                (clicked)="reset()"
            >
                {{ '$.reset' | translate }}
            </plmt-button>
        </div>
    </core-form>
</sl-dialog>
