<h4>{{ '_$$.filterTemplate.extends.header' | translate }}</h4>

<div class="tab-button" *ngIf="subType !== FilterTemplateUIType.TreeView">
    <plmt-button
        size="small"
        test-id="Dataset"
        prefixIcon="gear"
        [variant]="
            settingsBlock !== SettingsBlock.Dataset ? 'neutral' : 'primary'
        "
        [outline]="settingsBlock !== SettingsBlock.Dataset ? '' : undefined"
        (clicked)="toggleSelect(SettingsBlock.Dataset)"
    >
        {{ '_$$.filterTemplate.extends.tab.dataset' | translate }}
    </plmt-button>

    <plmt-button
        variant="primary"
        size="small"
        test-id="Custom"
        prefixIcon="sliders"
        [variant]="
            settingsBlock !== SettingsBlock.Custom ? 'neutral' : 'primary'
        "
        [outline]="settingsBlock !== SettingsBlock.Custom ? '' : undefined"
        (clicked)="toggleSelect(SettingsBlock.Custom)"
    >
        {{ '_$$.filterTemplate.extends.tab.custom' | translate }}
    </plmt-button>
</div>

<ng-container *ngIf="settingsBlock === SettingsBlock.Dataset">
    <div class="grid" style="--template: 1fr">
        <core-validate
            [serverErrorsMap]="{
                removed: '_$$.filterTemplate.errors.dataset.notExist'
            }"
        >
            <core-suggest
                viewKey="name"
                name="dataset_id"
                [label]="'_$$.filterTemplate.extends.dataset.label' | translate"
                [placeholder]="
                    '_$$.filterTemplate.extends.dataset.placeholder' | translate
                "
                [selected]="dataset"
                [suggestionsProvider]="datasetLoader"
                (onSelect)="onSelectDataset($event)"
            >
            </core-suggest>
        </core-validate>
    </div>

    <div
        *ngIf="subType !== FilterTemplateUIType.TreeView; else treeViewSettings"
    >
        <div class="grid" style="--template: 1fr var(--third)">
            <core-validate>
                <plmt-select
                    type="number"
                    name="dataset_select.view_column_id"
                    [label]="
                        '_$$.filterTemplate.extends.view.label' | translate
                    "
                    [placeholder]="
                        '_$$.filterTemplate.placeholder.selectColumn'
                            | translate
                    "
                    [disabled]="!dataset"
                    (sl-change)="onDatasetColumnSelected($event)"
                >
                    <sl-option
                        *ngFor="let column of dataset?.columns"
                        tooltipify
                        trackById
                        [disabled]="column | isColumnResultUnsaved"
                        [value]="column.id"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                columnIcons;
                                context: { $implicit: column }
                            "
                        ></ng-container>

                        {{ column.name }}
                    </sl-option>
                </plmt-select>
            </core-validate>

            <plmt-select
                clearable
                type="number"
                name="dataset_select.key_column_id"
                [label]="'_$$.filterTemplate.key' | translate"
                [placeholder]="
                    '_$$.filterTemplate.placeholder.select' | translate
                "
                [disabled]="!dataset"
            >
                <sl-option
                    *ngFor="let column of dataset?.columns"
                    tooltipify
                    trackById
                    [disabled]="column | isColumnResultUnsaved"
                    [value]="column.id"
                >
                    <ng-container
                        *ngTemplateOutlet="
                            columnIcons;
                            context: { $implicit: column }
                        "
                    ></ng-container>

                    {{ column.name }}
                </sl-option>
            </plmt-select>
        </div>

        <div class="grid" style="--template: 1fr var(--third)">
            <plmt-select
                clearable
                type="number"
                name="dataset_select.order_by"
                [label]="'_$$.filterTemplate.extends.sort.label' | translate"
                [placeholder]="
                    '_$$.filterTemplate.placeholder.selectColumn' | translate
                "
                [disabled]="!dataset"
                [showBetaBadge]="true"
                (sl-change)="onSortColumnSelected($event)"
            >
                <sl-option
                    *ngFor="let column of dataset?.columns"
                    tooltipify
                    trackById
                    [value]="column.id"
                    [disabled]="isColumnResultError(column)"
                >
                    <ng-container
                        *ngTemplateOutlet="
                            columnIcons;
                            context: { $implicit: column }
                        "
                    ></ng-container>

                    {{ column.name }}
                </sl-option>
            </plmt-select>

            <plmt-select
                clearable
                name="dataset_select.order_direction"
                [label]="
                    '_$$.filterTemplate.extends.sortDirection.label' | translate
                "
                [placeholder]="
                    '_$$.filterTemplate.placeholder.select' | translate
                "
                [disabled]="!dataset"
            >
                <sl-option [value]="SortDirection.Asc">
                    {{ SortDirection.Asc }}
                </sl-option>
                <sl-option [value]="SortDirection.Desc">
                    {{ SortDirection.Desc }}
                </sl-option>
            </plmt-select>
        </div>
    </div>
    <div *ngIf="dataset" class="filters">
        <h4>
            {{ '_$$.filterTemplate.extends.filter.header' | translate }}
        </h4>

        <p>
            {{ '_$$.filterTemplate.extends.filter.description' | translate }}
        </p>

        <core-local-filters
            [dataset]="dataset"
            [filters]="filtersValues"
        ></core-local-filters>
    </div>
</ng-container>

<ng-container *ngIf="settingsBlock === SettingsBlock.Custom">
    <div class="custom-select-item">
        <div class="columns-block">
            <core-validate>
                <sl-input
                    name="custom_select.name_suggest_column"
                    [placeholder]="
                        '_$$.filterTemplate.placeholder.input' | translate
                    "
                    [label]="
                        '_$$.filterTemplate.extends.name_column.label'
                            | translate
                    "
                ></sl-input>
            </core-validate>
            <plmt-select
                class="column-type"
                type="number"
                name="custom_select.suggest_column_type"
                [label]="
                    '_$$.filterTemplate.placeholder.custom_column_type_label'
                        | translate
                "
            >
                <sl-icon
                    slot="prefix"
                    [attr.name]="
                        customSelectForm.controls.suggest_column_type?.value!
                            | columnTypeToIconName
                    "
                ></sl-icon>
                <sl-option *ngFor="let type of columnTypes" [value]="type">
                    <sl-icon
                        slot="prefix"
                        [attr.name]="type | columnTypeToIconName"
                    ></sl-icon>

                    {{ '_$$.filterTemplate.optionType.' + type | translate }}
                </sl-option>
            </plmt-select>
        </div>

        <div class="columns-block">
            <core-validate>
                <sl-input
                    name="custom_select.name_key_column"
                    [placeholder]="
                        '_$$.filterTemplate.placeholder.input' | translate
                    "
                    [label]="
                        '_$$.filterTemplate.extends.name_column.label'
                            | translate
                    "
                ></sl-input>
            </core-validate>
            <plmt-select
                class="column-type"
                type="number"
                name="custom_select.key_column_type"
                [label]="
                    '_$$.filterTemplate.placeholder.custom_column_type_label'
                        | translate
                "
            >
                <sl-icon
                    slot="prefix"
                    [attr.name]="
                        customSelectForm.controls.key_column_type?.value!
                            | columnTypeToIconName
                    "
                ></sl-icon>
                <sl-option *ngFor="let type of columnTypes" [value]="type">
                    <sl-icon
                        slot="prefix"
                        [attr.name]="type | columnTypeToIconName"
                    ></sl-icon
                    >{{ '_$$.filterTemplate.optionType.' + type | translate }}
                </sl-option>
            </plmt-select>
        </div>
    </div>

    <div class="custom-select-item custom-values">
        <label>
            {{ '_$$.filterTemplate.extends.view.label' | translate }}
        </label>

        <label>
            {{ '_$$.filterTemplate.key' | translate }}
        </label>
    </div>

    <div class="custom-select-item-wrap">
        <div
            *ngFor="let control of customValues.controls; index as i"
            class="custom-select-item"
        >
            <core-validate noMessage>
                <sl-input
                    class="custom-label"
                    [name]="['custom_select.values', i, 'label']"
                    [placeholder]="
                        '_$$.filterTemplate.placeholder.input' | translate
                    "
                >
                </sl-input
            ></core-validate>

            <div class="key-with-btn">
                <core-validate noMessage>
                    <sl-input
                        class="key"
                        [name]="['custom_select.values', i, 'key']"
                        [placeholder]="
                            '_$$.filterTemplate.placeholder.inputKey'
                                | translate
                        "
                    >
                    </sl-input>
                </core-validate>

                <sl-icon-button
                    class="remove-icon"
                    *ngIf="customValues.controls.length > 1"
                    name="x"
                    test-id="Delete"
                    (click)="deleteCustomSelectItem(i)"
                ></sl-icon-button>
            </div>
        </div>
    </div>

    <plmt-button
        variant="primary"
        class="add-custom-select-item"
        test-id="More"
        clear
        (clicked)="addCustomSelectItem(defaultFilterTemplateCustomRow)"
    >
        {{ '_$$.filterTemplate.extends.add' | translate }}
    </plmt-button>
</ng-container>

<div class="grid" style="--template: var(--third) 1fr">
    <core-validate>
        <plmt-select
            name="action"
            [label]="'_$$.filterTemplate.filterMethod' | translate"
            [placeholder]="'_$$.filterTemplate.placeholder.select' | translate"
            [disabled]="filterMethods.length === 0"
        >
            <sl-option
                *ngFor="let method of filterMethods"
                tooltipify
                [value]="method"
            >
                {{ '$$.filters.method.' + method | translate }}
            </sl-option>
        </plmt-select>
    </core-validate>

    <ng-container *ngIf="canShowFilterValue">
        <core-filter-value
            *ngIf="column"
            [state]="filterValueState"
            [aggFn]="formInstance.controls.defaultValueAggFn.value"
            [filterType]="type"
            [datasetId]="dataset!.id"
            [column]="column"
            [filters]="filtersValues"
            [showMinMax]="canShowMinMax"
            [sortColumnBaseType]="sortColumn?.base_type"
            [subType]="subType"
            [treeSelectConfig]="treeSelectConfig"
            [keyColumnId]="keyColumnId"
            [sortSettings]="sortSettings"
            (change)="onFilterStateChange($event)"
            (radioMinMaxChange)="onRadioMinMaxChange($event)"
        ></core-filter-value>
        <core-filter-value
            *ngIf="!column"
            [state]="filterValueState"
            [aggFn]="formInstance.controls.defaultValueAggFn.value"
            [filterType]="type"
            [datasetId]="dataset!.id"
            [showMinMax]="canShowMinMax"
            [subType]="subType"
            [treeSelectConfig]="treeSelectConfig"
            (change)="onFilterStateChange($event)"
            (radioMinMaxChange)="onRadioMinMaxChange($event)"
        ></core-filter-value>
    </ng-container>

    <plmt-filter-value-default
        *ngIf="settingsBlock === SettingsBlock.Custom"
        name="default_value"
        hasValueName="has_default_value"
        [defaultValues]="defaultValues"
        [uiType]="type"
    ></plmt-filter-value-default>
</div>

<ng-template #columnIcons let-column>
    <app-calculate-column-icon
        class="calc-column-icon"
        slot="prefix"
        [column]="column"
    ></app-calculate-column-icon>

    <core-column-type-icon
        class="base-type-column-icon"
        slot="prefix"
        [column]="column"
    ></core-column-type-icon>
</ng-template>

<ng-template #treeViewSettings>
    <plmt-tree-filter *ngIf="dataset" [dataset]="dataset"></plmt-tree-filter>
</ng-template>
