import { NgIf } from '@angular/common';
import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { IconContainerComponent } from '../../../components/sl-icon-container/sl-icon-container.component';
import { IconInput } from '../../../interfaces/sl-icon.type';
import { TooltipifyModule } from '../../../modules/tooltipify/tooltipify.module';
import { getHost } from '../../../tools/get-host';

@Component({
    selector: 'plmt-toggle-item',
    standalone: true,
    imports: [IconContainerComponent, NgIf, TooltipifyModule],
    templateUrl: './plmt-toggle-item.component.html',
    styleUrls: ['./plmt-toggle-item.component.less'],
})
export class PlmtToggleItemComponent {
    @Input()
    prefix?: IconInput;

    @Input()
    suffix?: IconInput;

    @Input()
    maxWidth?: number;

    @HostBinding('class.selected')
    @Input()
    selected: boolean = false;

    @Output()
    onChooseToggle = new EventEmitter<PlmtToggleItemComponent>();

    @HostListener('click')
    onItemClick(): void {
        this.selectItem();
    }

    readonly host = getHost<HTMLDivElement>();

    selectItem(): void {
        if (this.selected) return;

        this.onChooseToggle.emit(this);

        this.selected = true;
    }
}
