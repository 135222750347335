import {
    Component,
    ContentChild,
    CUSTOM_ELEMENTS_SCHEMA,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { TagSize, TagVariant } from './model';

@Component({
    selector: 'plmt-tag',
    templateUrl: './plmt-tag.component.html',
    styleUrls: ['./plmt-tag.component.less'],
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlmtTagComponent {
    @ContentChild('tag', { read: TemplateRef }) template!: TemplateRef<unknown>;

    @Input()
    size: keyof typeof TagSize = 'medium';

    @Input()
    variant: TagVariant = 'primary';

    @Input()
    pill: '' | undefined = void 0;

    @Input()
    removable: '' | undefined = void 0;

    @Input()
    disabled = false;

    @Output() remove = new EventEmitter<void>();

    readonly TagSize = TagSize;
}
