<ng-container *ngIf="toggles.length; else content">
    <plmt-toggle-item
        *ngFor="let toggle of toggles; trackByKey: 'callback'"
        [prefix]="toggle.prefix"
        [suffix]="toggle.suffix"
        [maxWidth]="toggle.maxWidth"
        [selected]="toggle.selected || false"
        (onChooseToggle)="choiceToggle(toggle, $event)"
    >
        <ng-container *ngIf="toggle.text">
            {{ toggle.text | translate }}
        </ng-container>
    </plmt-toggle-item>
</ng-container>

<ng-template #content><ng-content></ng-content></ng-template>
