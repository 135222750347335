import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ShoelaceModule } from 'shoelace-style-angular';

import { SpinnerModule } from '../../../../components/spinner/spinner.module';
import { TestIdDirectiveModule } from '../../../../directives/test-id.directive';
import { TooltipifyModule } from '../../../tooltipify/tooltipify.module';
import { TranslateModule } from '../../../translate/translate.module';
import { DependenceApiService } from '../../api/related-info-api.service';
import { DeleteConfirmComponent } from './dependencies-confirm.component';
import { RelatedEntityComponent } from './related-entity/related-entity.component';
import { AvatarModule } from '../../../../plmt-ui-components/avatar/avatar.module';
import { PlmtButtonComponent } from '../../../../plmt-ui-components';
import { PlmtDetailsComponent } from '../../../../plmt-ui-components/plmt-details/plmt-details.component';

export * from '../../enums/related-info-enum';
export * from './dependencies-confirm.component';

@NgModule({
    declarations: [DeleteConfirmComponent, RelatedEntityComponent],
    imports: [
        AvatarModule,
        CommonModule,
        ShoelaceModule,
        SpinnerModule,
        TranslateModule,
        TooltipifyModule,
        TestIdDirectiveModule,
        PlmtButtonComponent,
        PlmtDetailsComponent,
    ],
    providers: [DependenceApiService],
    exports: [DeleteConfirmComponent, RelatedEntityComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DependenceConfirmModule {}
