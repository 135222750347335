<ng-container [ngSwitch]="type">
    <p *ngSwitchDefault>
        <span tooltipify>{{ item.name }}</span>
    </p>

    <p *ngSwitchCase="UIType.ProjectIcon">
        <sl-icon
            class="project-icon"
            library="core"
            [name]="item.icon"
        ></sl-icon>
        <span tooltipify>{{ item.name }}</span>
    </p>

    <p *ngSwitchCase="UIType.Avatar">
        <plmt-avatar size="XSmall" [user]="getUserAvatarProps()"></plmt-avatar>
        <span tooltipify>{{ item.name }}</span>
    </p>
</ng-container>
